import { Col, Row, Select, Space } from "antd"; // Import antd components
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent"; // Import error message component
import { Formik, Form } from "formik"; // Import formik components
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useInsertionEffect,
} from "react"; // Import react hooks
import { useSelector } from "react-redux"; // Import react-redux
import { getCurrentToken, getOrganizationId } from "store/slices/loginSlice"; // Import loginSlice
import { itemsByItemTypeId, itemTypesByProjectId } from "utils/Actions"; // Import graphql queries
import { debounceFn } from "utils/Helper/commonMethods"; // Import debounce function
import {
  listItemsSearch,
  listItemsTypesSearch,
  listProjectsSearch,
} from "utils/RESTApi"; // Import RESTApi
import * as Yup from "yup"; // Import yup
import { fetchData } from "../commonFunction"; // Import fetchData function
import { CommonError } from "utils/Helper/CommonError"; // Import CommonError

const AssociateForm = (props) => {
  const {
    actionType,
    projectData,
    itemTypeData,
    formName,
    handleSubmitForm = () => {},
    dataToEdit = [],
    bulkEdit,
    documentId,
    setFormRef,
  } = props; // Destructuring props

  const userOrganizationId = useSelector(getOrganizationId); // Get user organization id from login slice
  const formikRef = useRef(); // Formik ref

  useInsertionEffect(() => {
    setFormRef(formikRef);
  }, [formikRef]);

  // state variables :: begin
  const [itemTypeList, setItemTypeList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [itemsValue, setItemsValue] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedItemTypes, setSelectedItemTypes] = useState([]);
  const [filterItemTypes, setFilterItemTypes] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  const currentTokenId = useSelector(getCurrentToken); // Get current token from login slice

  useEffect(() => {
    const projects = dataToEdit
      ?.map((item) => item?.projectId)
      .filter((item) => item !== null);

    if (dataToEdit.length > 0) {
      if (projects.length > 0) {
        setSelectedProjects(projects);
      } else {
        fetchItemTypes(false);
      }
    } else {
      // setSelectedProjects([]);
      // setItemTypeList([]);
    }
  }, [dataToEdit]);

  useEffect(() => {
    setFilterItemTypes(itemTypeList);
  }, [itemTypeList]);

  const filteredItemType = useMemo(() => {
    return formikRef?.current?.values?.itemType?.filter((item) =>
      filterItemTypes.some((itemType) => itemType?.id === item)
    );
  }, [filterItemTypes, formikRef?.current?.values?.itemType]);

  useEffect(() => {
    if (
      filteredItemType?.length === 0 &&
      dataToEdit?.length === 0 &&
      filteredItemType?.length !== formikRef?.current?.values?.itemType?.length
    ) {
      formikRef?.current?.setValues((prevValues) => ({
        ...prevValues,
        itemType: filteredItemType,
      }));
    }
  }, [filteredItemType, formikRef]);

  // fetch items by edit document :: begin
  useEffect(() => {
    const itemTypes = dataToEdit
      ?.map((item) => item?.itemTypeId)
      ?.filter((item) => item !== null);

    if (dataToEdit && itemTypes?.length > 0) {
      setItemsList([]); // Clear existing items
      setSelectedItemTypes(itemTypes); // Set selected item types

      // Create a Set to track processed items and avoid duplicates
      const processedItems = new Set();

      const fetchAllItems = async () => {
        setLoadingData(true);
        try {
          for (const itemTypeId of itemTypes) {
            const filter = {
              isDeleted: { ne: true },
            };

            const response = await itemsByItemTypeId(
              itemTypeId,
              filter,
              currentTokenId
            );
          }
        } catch (error) {
          CommonError(error);
        } finally {
          setLoadingData(false);
        }
      };

      fetchAllItems();
    }
  }, [dataToEdit]);
  // fetch items by edit document :: end

  useEffect(() => {
    if (
      itemTypeData &&
      dataToEdit.length === 0 &&
      formikRef?.current?.values?.project?.length === 0
    ) {
      setItemTypeList(itemTypeData);
    }
  }, [itemTypeData, dataToEdit]);

  useEffect(() => {
    if (projectData) {
      setProjectList(projectData);
    }
  }, [projectData]);

  // "Projects list" :: begins
  let projectOptions = useMemo(() => {
    const options = projectList?.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });

    return [...new Set(options)];
  }, [projectList]);
  // "Projects list" :: end

  useEffect(() => {
    if (selectedItemTypes?.length === 0) {
      setItemsList([]);
    }
  }, [selectedItemTypes]);

  useEffect(() => {
    setFilterItems(itemsList);
  }, [itemsList]);

  // "Items list" :: begins
  let itemsOptions = useMemo(() => {
    const options = filterItems?.map((item) => {
      return {
        label: item?.serialNumber,
        value: item?.id,
      };
    });

    return [...new Set(options)];
  }, [filterItems]);
  // "Items list" :: end

  // "Item Types" :: begin
  let itemTypesOptions = useMemo(() => {
    const options = filterItemTypes?.map((item) => {
      return {
        label: item?.number,
        value: item?.id,
      };
    });

    return [...new Set(options)];
  }, [filterItemTypes, projectList]);
  // "Item Types" :: begin

  // search function :: begin
  const handleCustomInputSearch = (searchText, inputName, filter = false) => {
    debouncedCustomHandleInput(searchText, inputName, filter);
  };

  const handleCustomSearch = (value, inputName, filter = false) => {
    if (!filter) {
      if (inputName === "project") {
        fetchData(
          userOrganizationId,
          listProjectsSearch,
          setLoadingData,
          setProjectList,
          setItemTypeList,
          setNextToken,
          value
        );
      } else if (inputName === "items") {
        fetchData(
          userOrganizationId,
          listItemsSearch,
          setLoadingData,
          setItemsList,
          setItemTypeList,
          setNextToken,
          value
        );
      } else {
        fetchData(
          userOrganizationId,
          listItemsTypesSearch,
          setLoadingData,
          setItemsList,
          setItemTypeList,
          setNextToken,
          value
        );
      }
    } else {
      if (inputName === "item") {
        const lowerCaseVal = value.toLowerCase();
        const filteredOptions = itemsList.filter((option) =>
          option.name.toLowerCase().includes(lowerCaseVal)
        );
        setFilterItems(filteredOptions);
      } else if (inputName === "Item Type") {
        const lowerCaseVal = value.toLowerCase();
        const filteredOptions = itemTypeList.filter((option) =>
          option.name.toLowerCase().includes(lowerCaseVal)
        );
        setFilterItemTypes(filteredOptions);
      }
    }
  };

  const debouncedCustomHandleInput = debounceFn(handleCustomSearch, 200);
  // search function :: end

  // 'validation schema' :: begins
  const validationSchema = Yup.object().shape({
    itemType:
      actionType !== "Item"
        ? Yup.array()
            .min(1, "Item type is required") // Ensure at least one item is selected
            .required("Item type is required")
        : Yup.array().when(["project"], {
            is: (value) => value === null || value.length === 0, // Check if project is null or empty
            then: () =>
              Yup.array()
                .min(1, "Please select Project or Item Type")
                .required("Please select Project or Item Type"),
            otherwise: () => Yup.array().nullable(),
          }),
    items:
      actionType === "Item"
        ? Yup.array()
            .min(1, "Please select at least one item") // Ensure at least one item is selected
            .required("Please select items")
        : Yup.array().nullable(),
  });
  // 'validation schema' :: end

  // 'Initial values' :: begins
  const initialValues = {
    project:
      dataToEdit
        ?.map((item) => item?.projectId)
        ?.filter((item) => item !== null) || [],
    itemType:
      dataToEdit
        ?.map((item) => item?.itemTypeId)
        ?.filter((item) => item !== null) || [],
    items:
      dataToEdit
        ?.map((item) => item?.itemsId)
        ?.filter((item) => item !== null) || [],
    documentId: bulkEdit && documentId,
  };
  // 'Initial values' :: end

  // (handle submit function) :: begins
  const handleSubmit = (values, { setSubmitting, validateForm }) => {
    validateForm().then((errors) => {
      const hasErrors = Object.keys(errors).length > 0;

      if (hasErrors) {
        setSubmitting(false);
        return;
      }

      if (bulkEdit && documentId) {
        values.documentId = documentId;
      }

      handleSubmitForm(values, formikRef); // Save form data
      // formikRef?.current?.resetForm();
    });
  };

  useEffect(() => {
    if (selectedItemTypes.length > 0) {
      for (let index = 0; index < selectedItemTypes.length; index++) {
        const element = selectedItemTypes[index];

        fetchItems(element, selectedItemTypes);
      }
    }
  }, [selectedItemTypes]);

  // {Fetch function for Items} :: begins
  const fetchItems = async (itemTypeId, itemTypeArray) => {
    setLoadingData(true);
    try {
      const filter = {
        isDeleted: { ne: true },
      };

      if (itemTypeId) {
        const response = await itemsByItemTypeId(
          itemTypeId,
          filter,
          currentTokenId
        );

        setItemsList((prevItemList) => {
          const newItems = response?.items || [];
          const combinedItems = [...prevItemList, ...newItems];

          // Create a Map to track items by ID for efficient lookup
          const uniqueItems = new Map();

          combinedItems.forEach((item) => {
            if (itemTypeArray.includes(item.itemTypeId)) {
              uniqueItems.set(item.id, item);
            }
          });

          return Array.from(uniqueItems.values());
        });
      }
    } catch (error) {
      CommonError(error);
    } finally {
      setLoadingData(false);
    }
  };
  // {Fetch function for Items} :: end

  useEffect(() => {
    if (selectedProjects.length > 0) {
      for (let index = 0; index < selectedProjects.length; index++) {
        const element = selectedProjects[index];

        fetchItemTypes(true, element, selectedProjects);
      }
    }
  }, [selectedProjects]);

  // {Fetch function for ItemTypes} :: begins
  const fetchItemTypes = async (
    projectSelected = false,
    projectId,
    selectedProjectsArray
  ) => {
    let filter = {
      isDeleted: { ne: true },
    };

    if (projectSelected) {
      setLoadingData(true);
      try {
        if (projectId) {
          const response = await itemTypesByProjectId(
            projectId,
            filter,
            null,
            500,
            currentTokenId
          );

          setItemTypeList((prevItemTypeList) => {
            const newItemTypes =
              response?.data?.itemTypesByProjectId?.items || [];
            const combinedItemTypes = [...prevItemTypeList, ...newItemTypes];

            return combinedItemTypes
              .filter(
                (item) => selectedProjectsArray.includes(item.projectId) // Ensure item is associated with selected projects
              )
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id) // Ensure uniqueness
              );
          });
        } else {
          setItemTypeList(itemTypeData);
        }
      } catch (error) {
      } finally {
        setLoadingData(false);
      }
    } else {
      fetchData(
        userOrganizationId,
        listItemsTypesSearch,
        setLoadingData,
        setItemsList,
        setItemTypeList,
        setNextToken
      );
    }
  };
  // {Fetch function for ItemTypes} :: end

  const deleteDeselectedItems = (id) => {
    const result = itemsList.filter((obj) => obj?.itemTypeId !== id);

    setItemsList(result);
  };
  // {Delete deselected associate item} :: end

  const handleProjectDeselect = (
    deselectedProjectId,
    values,
    setFieldValue
  ) => {
    // Get item types associated with only the deselected project
    const itemTypesToRemove = filterItemTypes
      .filter((itemType) => itemType.projectId === deselectedProjectId)
      .map((itemType) => itemType.id);

    // Get items associated with the removed item types
    const itemsToRemove = filterItems
      .filter((item) => itemTypesToRemove.includes(item.itemTypeId))
      .map((item) => item.id);

    // Filter out only the deselected project's data while keeping other selections
    const updatedItemTypes = values.itemType.filter(
      (typeId) => !itemTypesToRemove.includes(typeId)
    );

    const updatedItems = values.items.filter(
      (itemId) => !itemsToRemove.includes(itemId)
    );

    // Update form values
    setFieldValue("itemType", updatedItemTypes);
    setFieldValue("items", updatedItems);

    // Update the UI lists while preserving data from other projects
    setItemTypeList((prev) =>
      prev.filter((itemType) => itemType.projectId !== deselectedProjectId)
    );

    setItemsList((prev) =>
      prev.filter((item) => !itemTypesToRemove.includes(item.itemTypeId))
    );
  };
  // Function to fetch and store project associations
  const fetchProjectData = async (projectId) => {
    setLoadingData(true);
    try {
      const filter = { isDeleted: { ne: true } };

      // Fetch item types for the project
      const itemTypesResponse = await itemTypesByProjectId(
        projectId,
        filter,
        null,
        500,
        currentTokenId
      );

      const newItemTypes =
        itemTypesResponse?.data?.itemTypesByProjectId?.items || [];

      // Update itemTypeList with new items while preserving existing ones
      setItemTypeList((prev) => {
        const combined = [...prev, ...newItemTypes];
        return combined.filter(
          (type, index, self) =>
            index === self.findIndex((t) => t.id === type.id)
        );
      });
    } catch (error) {
      CommonError(error);
    } finally {
      setLoadingData(false);
    }
  };

  // UI component rendering :: begins
  return (
    <Formik
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ touched, handleChange, values, errors, setFieldValue }) => {
        return (
          <Form id={formName}>
            <Row gutter={bulkEdit && 16}>
              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="project">Project</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      className="custom-inner-margin custom_multiselection"
                      style={{
                        width: "100%",
                      }}
                      name="project"
                      mode="multiple"
                      allowClear
                      value={values.project ? values.project : []}
                      placeholder="Please select project"
                      onSearch={async (input) => {
                        handleCustomInputSearch(input, "project");
                      }}
                      onSelect={(e) => {
                        if (
                          values?.project?.length === 0 &&
                          values?.itemType?.length > 0
                        ) {
                          setFieldValue("itemType", []);
                          setFieldValue("items", []);
                          setSelectedItemTypes([]);
                          // setItemsList([]);
                        }
                      }}
                      onChange={(selected) => {
                        setFieldValue("project", selected);

                        // Fetch data for newly selected projects
                        const newProjects = selected.filter(
                          (projectId) => !values.project.includes(projectId)
                        );
                        newProjects.forEach((projectId) => {
                          fetchProjectData(projectId);
                        });

                        setSelectedProjects(selected);
                        if (values?.itemType?.length === 0) {
                          setItemsList([]);
                          setFieldValue("itemType", []);
                          setFieldValue("items", []);
                          setSelectedItemTypes([]);
                        }
                      }}
                      onClear={() => {
                        fetchItemTypes(false);
                        fetchItems(false);
                        setSelectedProjects([]);
                        setSelectedItemTypes([]);
                        setItemTypeList([]);
                        setItemsList([]);
                        setFieldValue("project", []);
                        setFieldValue("itemType", []);
                        setFieldValue("items", []);
                        setSelectedItemTypes([]);
                      }}
                      // onSelect={(e) => {
                      //   const projects = [];
                      //   projects.push(e);
                      // }}
                      onDeselect={(projectId) => {
                        if (values.project.length === 1) {
                          fetchItemTypes(false);
                          fetchItems(false);
                          setSelectedProjects([]);
                          setSelectedItemTypes([]);
                          setItemTypeList([]);
                          setItemsList([]);
                          setFieldValue("project", []);
                          setFieldValue("itemType", []);
                          setFieldValue("items", []);
                          setSelectedItemTypes([]);
                        }

                        handleProjectDeselect(projectId, values, setFieldValue);
                      }}
                      loading={loadingData}
                      options={projectOptions}
                      autoFocus
                      showSearch
                      filterOption={false} // Disable client-side filtering
                    />
                  </Space>
                </div>
              </Col>

              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="itemType">Item Type(s)</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      name="itemType"
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      className="custom_multiselection"
                      allowClear
                      value={values.itemType ? values.itemType : []}
                      placeholder="Please select item types"
                      onSearch={async (input) => {
                        handleCustomInputSearch(
                          input,
                          "Item Type",
                          values?.project?.length > 0
                        );
                      }}
                      onChange={(e) => {
                        setFieldValue("itemType", e);
                        setSelectedItemTypes(e);
                      }}
                      onClear={() => {
                        setSelectedItemTypes([]);
                        setFieldValue("items", []);
                        setItemsList([]);
                      }}
                      onSelect={(e) => {
                        // fetchItems(e);
                      }}
                      showSearch
                      filterOption={false} // Disable client-side filtering
                      onDeselect={(e) => {
                        deleteDeselectedItems(e);
                        setFieldValue("items", itemsValue);

                        const filter = values?.itemType?.filter(
                          (item) => item !== e
                        );
                        setItemsList(
                          itemsList?.filter((items) => items?.itemTypeId !== e)
                        );
                        if (filter?.length === 0) {
                          setItemsList([]);
                        }

                        if (values?.itemType?.length === 1) {
                          setFieldValue("items", []);
                          setItemsList([]);
                        }
                      }}
                      loading={loadingData}
                      options={itemTypesOptions}
                      autoFocus
                    />
                  </Space>
                  {touched?.itemType && (
                    <ErrorMessageComponent error={errors?.itemType} />
                  )}
                </div>
              </Col>
            </Row>

            {actionType === "Item" && (
              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="items">Item(s)</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      name="items"
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      className="custom_multiselection"
                      allowClear
                      value={values.items ? values.items : []}
                      placeholder="Please select items"
                      onSearch={async (input) => {
                        if (values?.itemType?.length === 0) {
                          return;
                        } else {
                          handleCustomInputSearch(
                            input,
                            "items",
                            values?.itemType?.length > 0
                          );
                        }
                      }}
                      onChange={(e) => setFieldValue("items", e)}
                      loading={loadingData}
                      options={itemsOptions}
                      autoFocus
                      // filterOption={(input, option) =>
                      //   option.label
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      showSearch
                      filterOption={false} // Disable client-side filtering
                    />
                  </Space>
                  {touched?.items && (
                    <ErrorMessageComponent error={errors?.items} />
                  )}
                </div>
              </Col>
            )}
          </Form>
        );
      }}
    </Formik>
  );
  // UI component rendering :: ends
};

export default AssociateForm;
