import React, { useEffect, useRef, useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Modal, Button, Select, Space, Tag, message } from "antd";
import { useSelector } from "react-redux";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import SelectInput from "Common/Components/SelectInput/SelectInput";
import { getCurrentToken, getOrganizationId } from "store/slices/loginSlice";
import {
  listUserOfOrganization,
  createNotificationGroups,
  updateNotificationGroups,
} from "utils/Actions";
import { CommonError } from "utils/Helper/CommonError";
const CreateNotificationGroup = ({
  open,
  onCancel,
  loading: initialLoading,
  notificationDataEdit,
  notificationData,
  handleCustomSubmit,
}) => {
  const formikRef = useRef();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);
  // Redux selectors
  const currentIdToken = useSelector(getCurrentToken);
  const userOrganizationId = useSelector(getOrganizationId);
  // Fetch organization users
  const fetchOrganizationUsers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await listUserOfOrganization(
        userOrganizationId,
        currentIdToken
      );
      const transformedOptions = response.items.map((item) => ({
        value: item.User.id,
        label: `${item.User.firstName} ${item.User.lastName}`,
        email: item.User.email,
      }));
      setContactOptions(transformedOptions);
    } catch (err) {
      CommonError(err);
    } finally {
      setLoading(false);
    }
  }, [userOrganizationId, currentIdToken]);
  // Fetch users on component mount or when organization changes
  useEffect(() => {
    if (open) {
      fetchOrganizationUsers();
    }
  }, [open, fetchOrganizationUsers]);
  // Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Group name is required")
      .min(3, "Group name must be at least 3 characters")
      .max(50, "Group name must not exceed 50 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters")
      .max(250, "Description must not exceed 250 characters"),
    memberIds: Yup.array()
      .of(Yup.string().required("Member ID is required"))
      .min(1, "At least one contact is required")
      .max(10, "Maximum 10 contacts allowed"),
  });
  // Initial Values
  const getInitialValues = () => {
    if (notificationDataEdit && notificationData) {
      return {
        id: notificationData.id || null,
        name: notificationData.name || "",
        description: notificationData.description || "",
        memberIds: notificationData.memberIds || [],
      };
    }
    return {
      id: null,
      name: "",
      description: "",
      memberIds: [],
    };
  };
  // Tag render function for multi-select
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="cyan"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };
  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      // Validate all fields
      await validationSchema.validate(values, { abortEarly: false });
      // Prepare mutation input exactly matching the GraphQL schema
      const mutationInput = {
        organizationID: userOrganizationId,
        name: values.name,
        description: values.description,
        memberIds: values.memberIds,
      };
      const UpdatemutationInput = {
        id: values.id,
        organizationID: userOrganizationId,
        name: values.name,
        description: values.description,
        memberIds: values.memberIds,
      };
      // Call create or update mutation
      const response = notificationDataEdit
        ? await updateNotificationGroups(UpdatemutationInput, currentIdToken)
        : await createNotificationGroups(mutationInput, currentIdToken);
      // Show success message
      message.success(
        notificationDataEdit
          ? "Notification Group Updated Successfully"
          : "Notification Group Created Successfully"
      );
      // Close modal and reset form
      onCancel();
      setLoading(false);
      formikRef.current.resetForm();
    } catch (err) {
      CommonError(err);
      onCancel();
      setSubmitting(false);
    } finally {
      setLoading(false);
      onCancel();
    }
    handleCustomSubmit();
    console.log("Update");
  };
  // Handle cancel and form reset
  const handleCancel = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    onCancel();
  };
  return (
    <Modal
      title={
        notificationDataEdit
          ? `Edit ${notificationData.name}`
          : "Create Notification Group"
      }
      width={350}
      centered
      className="modal"
      open={open}
      maskClosable={true}
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          form="NotificationGroupForm"
          htmlType="submit"
          loading={loading}
          className="w-100"
        >
          {notificationDataEdit ? "Update" : "Create"}
        </Button>,
      ]}
    >
      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize={true}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form id="NotificationGroupForm">
            <div className="mb-10">
              <label htmlFor="name">Group Name</label>
              <Field
                type="text"
                name="name"
                as={Input}
                placeholder="Enter Group Name"
                disabled={notificationDataEdit ? true : false}
              />
              {touched.name && <ErrorMessageComponent error={errors?.name} />}
            </div>
            <div className="mb-10">
              <label htmlFor="description">Description</label>
              <Field
                as={TextArea}
                name="description"
                placeholder="Enter Description"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
              {touched.description && (
                <ErrorMessageComponent error={errors?.description} />
              )}
            </div>
            <div className="mb-10">
              <label htmlFor="memberIds">Select Contacts</label>
              <Field name="memberIds">
                {({ field, form }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    className="custom_multiselection"
                    style={{ width: "100%" }}
                    placeholder="Select Contacts"
                    tagRender={tagRender}
                    options={contactOptions}
                    onChange={(value) => {
                      form.setFieldValue("memberIds", value);
                    }}
                  />
                )}
              </Field>
              {touched.memberIds && (
                <ErrorMessageComponent error={errors?.memberIds} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default CreateNotificationGroup;
