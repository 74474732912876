import { Button, Modal } from "antd"; // Import antd components
import React, { memo } from "react"; // Import react hooks
import "./../../custom.less"; // Import custom styles

const DeleteModal = (props) => {
  // props
  const {
    isDelete,
    setIsDelete,
    message,
    header,
    handleSubmit,
    loadingdata,
    dataClear,
    footerSubmit = "Delete",
  } = props;
  // const keyValuePairs = Object.entries(data);

  // UI component rendering :: begins
  return (
    <Modal
      title={header}
      className="modal one-btn-footer"
      centered
      width={350}
      open={isDelete}
      onCancel={() => {
        setIsDelete(false);
        if (dataClear) {
          dataClear();
        }
      }}
      footer={[
        <Button
          type="primary"
          danger
          form="myForm"
          loading={loadingdata}
          onClick={() => handleSubmit()}
        >
          {footerSubmit}
        </Button>,
      ]}
    >
      <div className="text-center ">
        <svg
          width="51"
          height="50"
          viewBox="0 0 51 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" width="50" height="50" rx="25" fill="#FFF1F0" />
          <g clipPath="url(#clip0_181924_7341)">
            <path
              d="M25.4998 27.8556C26.1062 27.8556 26.5939 27.3856 26.5939 26.8013V21.8808C26.5939 21.2964 26.1062 20.8264 25.4998 20.8264C24.8935 20.8264 24.4058 21.2964 24.4058 21.8808V26.8013C24.4058 27.3856 24.8935 27.8556 25.4998 27.8556Z"
              fill="#FF4D4F"
            />
            <path
              d="M25.4999 32.0732C26.3055 32.0732 26.9586 31.4438 26.9586 30.6674C26.9586 29.8909 26.3055 29.2615 25.4999 29.2615C24.6942 29.2615 24.0411 29.8909 24.0411 30.6674C24.0411 31.4438 24.6942 32.0732 25.4999 32.0732Z"
              fill="#FF4D4F"
            />
            <path
              d="M34.5651 35.5H16.4351C15.5256 35.5 14.6982 35.0347 14.2218 34.2549C13.7172 33.429 13.7035 32.3953 14.1858 31.5574L23.2508 15.8057C23.7213 14.9881 24.5624 14.5 25.5001 14.5C26.4378 14.5 27.2789 14.9881 27.7494 15.8057L36.8144 31.5574C37.2967 32.3957 37.283 33.4294 36.7784 34.2549C36.302 35.0343 35.4746 35.5 34.5651 35.5ZM25.5001 16.3452C25.2649 16.3452 25.0552 16.4748 24.9253 16.7006L15.8603 32.4524C15.6752 32.774 15.7449 33.1109 15.8716 33.3187C15.9482 33.4444 16.1233 33.6548 16.4351 33.6548H34.5651C34.8765 33.6548 35.0515 33.4444 35.1286 33.3187C35.2558 33.1109 35.3255 32.774 35.14 32.4524L26.075 16.7006C25.945 16.4748 25.7353 16.3452 25.5001 16.3452Z"
              fill="#FF4D4F"
            />
          </g>
          <defs>
            <clipPath id="clip0_181924_7341">
              <rect
                width="23.3333"
                height="23.3333"
                fill="white"
                transform="translate(13.8333 13.3334)"
              />
            </clipPath>
          </defs>
        </svg>

        <p className="deleteHeader mb-5 mt-10">
          {message || "Are you sure you want to delete this Item?"}
        </p>
      </div>

      {/* {keyValuePairs.map(([key, value]) => {
        return (
          <div className="d-flex deleteText">
            <p className="keyDisplay mr-5">{key}</p> :
            <p className="ml-5">{value}</p>
          </div>
        );
      })} */}
    </Modal>
  );
  // UI component rendering :: ends
};

export default memo(DeleteModal);
