import React, { useState, useEffect } from "react";
import { Badge, Button, List, Popover, Typography, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  notificationsUsersByOrganizationID,
  updateNotificationsUsers,
} from "utils/Actions";
import { BellOutlined, MailOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import {
  getCurrentIdToken,
  getOrganizationId,
  isSuperAdmin as SUPERADMIN,
  isAdmin as Organizationadmin,
  getCurrentUser,
} from "store/slices/loginSlice";
import "../notifications.less";
import { dateTimeConversionBasedOnTimeZone } from "utils/Helper/commonMethods";
import { CommonError } from "utils/Helper/CommonError";

const { Text } = Typography;

const NotificationPopover = () => {
  const userOrganizationId = useSelector(getOrganizationId);
  const currentIdToken = useSelector(getCurrentIdToken);
  const isSuperAdmin = useSelector(SUPERADMIN);
  const UserIsAdmin = useSelector(Organizationadmin);
  const userID = useSelector(getCurrentUser);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [dynamicUrl] = useSearchParams();
  const searchParams = dynamicUrl.get("notifications_id");

  const fetchNotifications = async () => {
    if (!userOrganizationId || !currentIdToken) return;

    try {
      setLoading(true);

      let filter = {
        isDeleted: { ne: true },
        userId: { eq: userID.sub },
        isRead: { eq: false },
        // organizationID: { eq: userOrganizationId }, // Phase 2
      };

      const response = await notificationsUsersByOrganizationID(
        userOrganizationId,
        currentIdToken,
        filter
      );

      const sortedNotifications = (response?.items || []).sort((a, b) => {
        return (
          new Date(b.Notifications.createdAt) -
          new Date(a.Notifications.createdAt)
        );
      });

      setNotifications(sortedNotifications);
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userOrganizationId && currentIdToken) {
      fetchNotifications();
    }
  }, [userOrganizationId, currentIdToken]);

  useEffect(() => {
    if (searchParams) {
      setTimeout(() => {
        fetchNotifications();
      }, 1500);
    }
  }, [searchParams]);

  const handleViewDetails = (e, notificationId) => {
    navigate(`/notifications?notifications_id=${notificationId}`);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notificationContent = (
    <div className="notification-container">
      <div className="notification-header">
        <Row justify="space-between" align="middle">
          <Text>Notifications ({notifications.length})</Text>
        </Row>
      </div>

      <List
        className="notification-list"
        loading={loading}
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item className="notification-item">
            <div className="notification-content">
              <div className="notification-body">
                <Row
                  align="middle"
                  justify="space-between"
                  className="notification-title-row"
                >
                  <Row align="middle" gutter={8}>
                    <MailOutlined className="icon" />
                    <Text>{item.Notifications.name}</Text>
                  </Row>
                  <Text type="secondary" className="date">
                    {dateTimeConversionBasedOnTimeZone(
                      item.Notifications.createdAt
                    )}
                  </Text>
                </Row>
                <Text className="description" ellipsis={{ rows: 3 }}>
                  {item.Notifications.description}
                </Text>
                <Button
                  type="link"
                  className="view-details-btn"
                  onClick={(e) => handleViewDetails(e, item.notificationId)}
                >
                  View Details <RightOutlined />
                </Button>
              </div>
            </div>
          </List.Item>
        )}
      />
    </div>
  );

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Popover
      content={notificationContent}
      trigger="click"
      open={open}
      placement="bottomRight"
      onOpenChange={handleToggle}
    >
      <Badge count={notifications?.length} className="mr-10">
        <Button shape="circle">
          <BellOutlined />
        </Button>
      </Badge>
    </Popover>
  );
};

export default NotificationPopover;
