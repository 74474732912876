import { message } from "antd";
import { Storage } from "aws-amplify";
import axios from "axios";
import { debounce } from "lodash";
import Papa from "papaparse";
import moment from "moment-timezone";
import momentTZ from "moment-timezone";
import { timeZoneIANA, defaultDateTimeFormat } from "utils/Constants";
import { CommonError } from "./CommonError";
export const saveFileToS3 = async (file, folder, level = "public") => {
  try {
    return await Storage.put(
      `${folder}/${file.name.replace(
        /\.[^/.]+$/,
        ""
      )}_${Date.now()}.${file.name.replace(/(.*)\./g, "")}`.replace(/ /g, "_"),
      file,
      {
        level,
      }
    );
  } catch (error) {
    return error; // Explicitly returning a value
  }
};

export const saveFolderToS3 = async (folder, level = undefined) => {
  try {
    const folderKey = level ? `${level}/${folder}/` : `${folder}/`;
    const metadataKey = `${folderKey}folder-metadata.json`; // Metadata file key

    const s3Objects = await Storage.list(folderKey);

    // Check if the folder is already created
    if (!s3Objects.Items || s3Objects.Items.length === 0) {
      // Create an empty folder by putting an object with no content
      await Storage.put(folderKey, {}, { level });

      // Add createdAt timestamp in a metadata file
      const createdAt = new Date().toISOString();
      const metadata = {
        createdAt, // Timestamp of folder creation
        folderName: folder,
        name: folder,
      };

      // Upload the metadata file to S3
      await Storage.put(metadataKey, JSON.stringify(metadata), {
        level,
        contentType: "application/json",
      });
    }

    // Loop through the files and re-upload (if necessary)
    for (const file of s3Objects.Items) {
      if (file.contentType.startsWith("application/x-directory")) continue;

      const fileContent = await Storage.get(file.key, { level });
      await Storage.put(`${folderKey}${file.key}`, fileContent);
    }

    return true;
  } catch (error) {
    console.error("Error saving folder to S3:", error);
    return false;
  }
};

export const fetchFoldersFromS3 = async (parentLevel) => {
  try {
    const result = await Storage.list(parentLevel);

    if (!result || !Array.isArray(result.results)) {
      throw new Error("Unexpected response structure from Storage.list()");
    }

    const folders = Array.from(
      new Set(
        result.results.map((item) => {
          return item.key.split("/").slice(0, -1).join("/");
        })
      )
    );

    const filteredFolders = folders.filter((folder) => folder !== "");
    const fullFolderPath = filteredFolders?.map((folder) => `${folder}/`);

    const folderMetadata = [];

    for (const folder of filteredFolders) {
      const metadataKey = `${folder}/folder-metadata.json`; // The metadata file key

      try {
        // Fetch the metadata file from S3
        const metadataBlob = await Storage.get(metadataKey, { download: true });

        // Log raw blob for debugging
        // Convert Blob to text using Blob.text()
        const metadataString = await metadataBlob.Body.text(); // Convert Blob to string
        // Parse metadata JSON string
        let metadata;
        try {
          metadata = JSON.parse(metadataString); // Parse metadata from JSON
        } catch (parseError) {
          console.error(
            `Error parsing metadata for folder: ${folder}`,
            parseError
          );
          folderMetadata.push({
            folder,
            createdAt: null,
            name: null,
          });
          continue;
        }

        // Check if the createdAt field exists
        if (!metadata.createdAt) {
          console.warn(`No createdAt found for folder: ${folder}`);
        }

        folderMetadata.push({
          folder,
          createdAt: metadata.createdAt || null, // Add createdAt if exists, otherwise null
          name: metadata.name || null,
        });
      } catch (error) {
        // Handle cases where the metadata file is missing or unreadable
        console.warn(
          `Metadata not found or error for folder: ${folder}`,
          error
        );
        folderMetadata.push({
          folder,
          createdAt: null,
          name: null,
        });
      }
    }

    return folderMetadata;
  } catch (error) {
    console.error("Error fetching folder list:", error);
    throw error;
  }
};

export const moveFileBetweenFoldersInS3 = async (
  sourceKey,
  destinationKey,
  level = "public"
) => {
  try {
    const sourceContent = await Storage.get(sourceKey, { level });

    const baseFileName = sourceKey.split("/").pop();

    const destinationPath = destinationKey.replace(/\/*[^/]*$/, "");
    const newDestinationKey = `${destinationPath}/${baseFileName}`;

    const result = await Storage.put(newDestinationKey, sourceContent, {
      level,
    });

    await Storage.remove(sourceKey, { level });

    return result;
  } catch (error) {
    console.error("Error moving file between folders in S3:", error);
    throw error;
  }
};

export const moveFolder = async (
  sourceFolder,
  destinationFolder,
  level = undefined
) => {
  try {
    const sourceFolderKey = level
      ? `${level}/${sourceFolder}/`
      : `${sourceFolder}/`;
    const destinationFolderKey = level
      ? `${level}/${destinationFolder}/`
      : `${destinationFolder}/`;

    // List all objects in the source folder
    const s3Objects = await Storage.list(sourceFolderKey);

    // Check if the folder is empty or doesn't exist
    if (!s3Objects || !s3Objects.results || s3Objects.results.length === 0) {
      throw new Error(
        `Source folder "${sourceFolder}" is empty or does not exist.`
      );
    }

    // Move all objects to the new folder
    for (const file of s3Objects.results) {
      if (file.key.endsWith("/")) continue; // Skip folder markers

      const fileContent = await Storage.get(file.key, {
        level,
        download: true,
      });

      const destinationKey = file.key.replace(
        sourceFolderKey,
        destinationFolderKey
      ); // New destination path

      // Upload the file to the new folder
      await Storage.put(destinationKey, fileContent.Body, {
        contentType: file.contentType,
        level,
      });
    }

    // Delete the original objects from the source folder
    for (const file of s3Objects.results) {
      await Storage.remove(file.key, { level });
    }
    return true;
  } catch (error) {
    console.error("Error moving folder:", error);
    return false;
  }
};

export const renameAndMoveFolder = async (
  sourceFolder,
  destinationFolder,
  newFolderName,
  level = undefined
) => {
  try {
    // Step 1: Move the folder
    await moveFolder(sourceFolder, destinationFolder, level);

    // Step 2: Update the metadata in the new location
    // await updateFolderMetadata(destinationFolder, newFolderName, level);

    return true;
  } catch (error) {
    console.error("Error renaming and moving folder:", error);
    return false;
  }
};

export const deleteFolderFromS3 = async (folder, level = undefined) => {
  try {
    const folderKey = level ? `${level}/${folder}/` : `${folder}/`;

    // List all objects in the folder
    const s3Objects = await Storage.list(folderKey, { level });

    // Check if the folder is empty or doesn't exist
    if (!s3Objects || !s3Objects.results || s3Objects.results.length === 0) {
      return true; // No files to delete
    }

    // Delete all objects in the folder (including files and subfolders)
    for (const file of s3Objects.results) {
      await Storage.remove(file.key, { level });
    }
    return true;
  } catch (error) {
    console.error("Error deleting folder from S3:", error);
    return false;
  }
};

export const saveFileToS3ForChat = async (file, folder) => {
  try {
    const key = `chat/${folder}/${file.name.replace(
      /\.[^/.]+$/,
      ""
    )}_${Date.now()}.${file.name.replace(/(.*)\./g, "")}`.replace(/ /g, "_");
    return await Storage.put(key, file);
  } catch (error) {
    return null;
  }
};

export const saveMultipleFilesToS3 = async (files, level = "public") => {
  try {
    const uploadPromises = files.map((file) =>
      Storage.put(
        `${file.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${Date.now()}.${file.name.replace(/(.*)\./g, "")}`.replace(
          / /g,
          "_"
        ),
        file,
        { level }
      )
    );

    const results = await Promise.all(uploadPromises);
    const newResults = results && results.map((result) => result.key);
    return newResults;
  } catch (error) {
    return null;
  }
};

export const getFileFromS3 = async (key, level = "public") => {
  try {
    return await Storage.get(key, { level });
  } catch (error) {
    return null; // Explicitly returning a value
  }
};

export const deleteFileFromS3 = async (key, level = "public") => {
  try {
    return await Storage.remove(key, { level });
  } catch (error) {
    return null; // Explicitly returning a value
  }
};
export const downloadPDF = async (pdfURL) => {
  try {
    const fileName = " getFilenameFromUrl(pdfURL);";
    const response = await axios.get(pdfURL, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    message.error(error?.message);
  }
};

export const dateTimeConversionBasedOnTimeZone = (
  dateTime,
  format = defaultDateTimeFormat,
  timeZoneAbbr = timeZoneIANA
) => {
  return momentTZ.tz(dateTime, timeZoneAbbr).format(format);
};

// export const Timezone = async () => {
//   const currentOrganization = useSelector(getCurrentOrganization);
//   return currentOrganization.appTimeZone ? currentOrganization.appTimeZone : timeZoneIANA;
// };

export const convertToOptions = (data) => {
  const options = data?.map((item) => {
    const option = {
      value: item?.id,
      label: item?.name,
      type: item?.type,
      requiredProperty: item?.isRequired,
      children: [], // Initialize an empty array for children
    };

    if (item?.PropertyDropdown && item?.PropertyDropdown?.items?.length > 0) {
      option.children = item.PropertyDropdown.items.map((child) => {
        const groupOption = {
          value: child?.id,
          label: child?.name,
          children: child?.options?.map((data) => ({
            value: data,
            label: data,
          })),
        };

        // Add a heading for each group
        groupOption?.children?.length > 0 &&
          groupOption.children.unshift({
            value: `${child?.id}_heading`,
            // label: `${child?.name} drop-down options`,
            label: `Drop-down options`,
            disabled: true,
          });

        return groupOption;
      });
    }

    // Add a heading for the main option
    option?.children?.length > 0 &&
      option.children.unshift({
        value: `${item?.id}_heading`,
        // label: `${item?.name} drop-down list`,
        label: `Drop-down list`,
        disabled: true,
      });

    return option;
  });
  options.unshift({
    value: `$`,
    label: "Custom properties list",
    disabled: true,
  });
  return options;
};

export const generatePassword = (passwordLength) => {
  var numberChars = "0123456789";
  var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var lowerChars = "abcdefghijklmnopqrstuvwxyz";
  var specialChars = "#?!@$%^&*-";
  var allChars = numberChars + upperChars + lowerChars + specialChars;
  var randPasswordArray = Array(passwordLength);
  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = upperChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray[3] = specialChars;
  randPasswordArray = randPasswordArray.fill(allChars, 4);
  return shuffleArray(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
  ).join("");
};

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}
export const getRandomDate = () => {
  const year = 2023;
  const month = Math.floor(Math.random() * 12) + 1;
  const day = Math.floor(Math.random() * 31) + 1;
  const hour = Math.floor(Math.random() * 24);
  const minute = Math.floor(Math.random() * 60);
  return `${month}/${day}/${year} ${hour}:${minute}`;
};
export function debounceFn(fn, delay) {
  const debouncedFn = debounce(fn, delay);
  return debouncedFn;
}

export async function setTimeIndates(date) {
  const localTime = moment(date);
  const currentTime = moment();

  const combinedTime = localTime.set({
    hour: currentTime.hour(),
    minute: currentTime.minute(),
    second: currentTime.second(),
  }); // Convert the local time to UTC  return debouncedFn;

  const dat = new Date(combinedTime).toISOString();
  return dat;

  //   const dat = moment(combinedTime).utc(true);
  //   return dat;
}

export async function breakWordsWithSpecialChars(inputString) {
  // Define regular expression to match words
  const wordRegex = /[a-zA-Z0-9]+/g;

  // Extract words from the input string
  const words = inputString.match(wordRegex);

  // Return the array of words
  return words || [];
}

export const setFullScreenWidth = (value, setDrawerWidth, setFullScreen) => {
  if (value) {
    window.getScreenDetails().then((res) => {
      let width = 0;
      if (document.activeElement.clientWidth > 1024) {
        width = document.activeElement.clientWidth - 257;
      } else {
        width = document.activeElement.clientWidth;
      }
      if (setDrawerWidth && setFullScreen) {
        setDrawerWidth(width); // state for drawer width
        setFullScreen(value); // state for fullscreen
      } else {
        CommonError({ message: "Drawer width or fullscreen state is missing" });
      }
    });
  } else {
    if (setDrawerWidth && setFullScreen) {
      setFullScreen(value); // state for fullscreen
      setDrawerWidth(706); // state for drawer width
    } else {
      CommonError({ message: "Drawer width or fullscreen state is missing" });
    }
  }
};

/**
   * Parses a CSV string and returns a promise that resolves with the filtered parsed data.
   * The data is filtered by removing any rows that have all empty values (after trimming).
   * @param {string} csvText - the CSV string to parse
   * @returns {Promise<Array<Object>>} - a promise that resolves with the filtered parsed data
   */
export const parseCSV = (csvText) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csvText, {
      header: true,
      skipEmptyLines: true,
      /**
       * Callback function for the CSV parsing process that filters out rows with all
       * empty values (after trimming) and resolves the promise with the filtered data.
       *
       * @param {Object} result - The result object from the CSV parsing process.
       * @param {Array} result.data - The array of parsed CSV rows.
       */
      complete: (result) => {
        const filteredData = result.data.filter(row =>
          Object.values(row).some(value => value.trim() !== "")
        );
        resolve(filteredData);
      },
      error: (error) => reject(error),
    });
  });
};
