import React, { useRef, useState } from "react"; // react hooks import
import { Button, Modal, Input } from "antd"; // Ant design components import
import { Formik, Form, Field } from "formik"; // formik components import
import * as Yup from "yup"; // yup import
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent"; // error message component import
import { CommonError } from "utils/Helper/CommonError"; // common error import
import { createDocuments } from "utils/Actions"; // graphql queries import

export default function AddNewFolder(props) {
  const {
    setAddNewFolderModal,
    addNewFolderModal,
    folderKey,
    userOrganizationId,
    currentIdToken,
  } = props; // Destructure props
  const formikRef = useRef(); // formik ref
  const [loading, setLoading] = useState(false); // loading state

  // form submit function :: begins
  const handleSubmit = async (value, ref) => {
    try {
      const input = {
        fileType: "Folder",
        name: value?.name,
        organizationID: userOrganizationId,
        createdAt: new Date(),
        parentId: folderKey,
        fileName: "",
      };

      setLoading(true);
      await createDocuments(input, currentIdToken);
      setAddNewFolderModal(false);
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
      ref?.resetForm();
      setAddNewFolderModal(false);
    }
  };
  // form submit function :: ends

  const initialValues = {
    name: "",
  };

  // validation schema :: begins
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter Folder Name")
      .test(
        "no-whitespace",
        "Folder name cannot contain whitespace",
        function (value) {
          return !/\s/.test(value);
        }
      ),
  });
  // validation schema :: ends

  // UI component rendering :: begins
  return (
    <Modal
      title="Add New Folder"
      width={400}
      centered
      className="modal"
      maskClosable={true}
      open={addNewFolderModal}
      onCancel={() => {
        setAddNewFolderModal(false);
        formikRef?.current?.resetForm();
      }}
      footer={[
        <Button
          type="primary"
          key={"submit"}
          form="addFolder"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Formik
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ touched, handleChange, values, errors, setFieldValue }) => {
          return (
            <Form id="addFolder">
              <div className="mb-10">
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  name="name"
                  as={Input}
                  placeholder="Enter name"
                />

                <ErrorMessageComponent error={errors?.name} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
  // UI component rendering :: ends
}
