import React, { useEffect, useLayoutEffect, useState } from "react"; // react hooks import
import { Tabs, Modal, Button } from "antd"; // ant design components import
import { useSelector } from "react-redux"; // react-redux import
import AssociateForm from "../AssociateForm"; // import AssociateForm component
import {
  createDocumentAttachments,
  deleteDocumentAttachments,
  updateDocumentAttachments,
} from "utils/Actions"; // import graphql queries import
import { CommonError } from "utils/Helper/CommonError"; // common error import
import { createAttachmentToDocument } from "../commonFunction"; // import createAttachmentToDocument function
import { associateApi } from "utils/RESTApi"; // import associateApi from RESTApi
import { getCurrentUser } from "store/slices/loginSlice"; // import getCurrentUser from loginSlice

export default function SingleEdit(props) {
  const {
    setSingleEdit,
    singleEdit,
    projectData,
    itemTypeData,
    documentData,
    organizationID,
    currentIdToken,
    editDocument = false,
    editToAttachment,
    activeForm,
    documentId,
    handleCustomForm = () => {},
  } = props; // destructuring props

  // state variables :: begin
  const [activeKey, setActiveKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState([]);
  const [idArrays, setIdArrays] = useState({
    projectId: [],
    itemTypeId: [],
    itemsId: [],
    attachmentId: [],
  });
  const [formRef, setFormRef] = useState(null);
  // state variables :: end

  const currentUser = useSelector(getCurrentUser); // get current user from login slice

  useLayoutEffect(() => {
    setActiveKey(activeForm);
  }, [activeForm]);

  useLayoutEffect(() => {
    setEditData(editToAttachment);

    setIdArrays({
      projectId: editToAttachment
        ?.map((item) => item?.projectId)
        ?.filter((item) => item !== null),
      itemTypeId: editToAttachment
        ?.map((item) => item?.itemTypeId)
        ?.filter((item) => item !== null),
      itemId: editToAttachment
        ?.map((item) => item?.itemsId)
        ?.filter((item) => item !== null),
      attachmentId: editToAttachment?.map((item) => item?.id),
    });
  }, [editToAttachment]);

  const onChange = (key) => {
    setActiveKey(key);
  };
  const items = [
    {
      key: 0,
      label: "Item",
    },
    {
      key: 1,
      label: "Item Types",
    },
  ];

  // Create or Update Attachment :: begin
  const handleAttachment = (input, ref) => {
    setLoading(true);
    try {
      input?.map(async (input) => {
        let params;
        if (input?.type === 1 && input?.itemId !== null) {
          input.itemsId = null;
        }

        if (!editDocument || editData.length === 0 || input?.id === null) {
          if (editData && input?.id === null) {
            delete input?.id;
          }

          // create attachment to document if not exist
          params = {
            createDocumentAttachments: [
              { ...input, modifiedBy: currentUser?.sub },
            ],
          };
        } else {
          // update attachment to document if exist
          params = {
            updateDocumentAttachments: [
              { ...input, modifiedBy: currentUser?.sub },
            ],
          };
        }

        if (
          (input?.type === 0 && input?.itemsId !== null) ||
          (input?.type === 1 && input?.itemTypeId !== null)
        ) {
          await associateApi(params).then((data) => {
            setSingleEdit(false);
            ref?.current?.resetForm();
            handleCustomForm(false); // call custom form function
            setLoading(false);
          });
        }

        if (
          (input?.type === 0 && input?.itemsId === null) ||
          (input?.type === 1 && input?.itemTypeId === null)
        ) {
          await deleteDocumentAttachments({ id: input?.id }, currentIdToken); // delete attachment if not exist
        }
      });
    } catch (error) {
      setLoading(false);
      CommonError(error);
    }
  };
  // Create or Update Attachment :: end

  // create document attachments :: begin
  const createAttachment = (value, ref) => {
    setLoading(true);
    let input;

    if (editDocument) {
      if (typeof documentId === "string") {
        input = createAttachmentToDocument(
          value?.itemType,
          value?.items,
          value?.project,
          documentId,
          editData.length > 0 && idArrays?.attachmentId,
          editDocument,
          organizationID,
          activeKey,
          editData
        );
        handleAttachment(input, ref);
      } else {
        const documentArray = documentId?.map((item) => item?.id);
        documentArray?.map((item) => {
          input = createAttachmentToDocument(
            value?.itemType,
            value?.items,
            value?.project,
            item,
            editData.length > 0 && idArrays?.attachmentId,
            editDocument,
            organizationID,
            activeKey,
            editData
          );
          handleAttachment(input, ref);
        });
      }
    } else if (!editDocument) {
      documentData?.map((data) => {
        input = createAttachmentToDocument(
          value?.itemType,
          value?.items,
          value?.project,
          data?.id,
          null,
          editDocument,
          organizationID,
          activeKey,
          editData
        );

        handleAttachment(input, ref);
      });
    }
  };
  // create document attachments :: end

  // UI component rendering :: begin
  return (
    <>
      <Modal
        title="Associate Document"
        width={350}
        centered
        className="modal"
        maskClosable={true}
        open={singleEdit}
        onCancel={() => {
          setSingleEdit(false);
          formRef?.current?.resetForm();
          formRef?.current?.setValues(() => {
            return {
              itemType: [],
              items: [],
              project: [],
            };
          });
        }}
        footer={[
          <Button
            onClick={() => {
              formRef?.current?.resetForm();
            }}
          >
            Reset
          </Button>,
          <Button
            type="primary"
            key={"submit"}
            form="createForm"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>,
        ]}
      >
        <Tabs
          className="full-tabs"
          centered
          activeKey={activeKey}
          items={items}
          onChange={onChange}
        />

        <AssociateForm
          actionType={activeKey === 0 ? "Item" : "Item Types"}
          projectData={projectData}
          itemTypeData={itemTypeData}
          formName={"createForm"}
          handleSubmitForm={(value, ref) => createAttachment(value, ref)}
          dataToEdit={editToAttachment}
          setFormRef={(ref) => {
            setFormRef(ref);
          }}
        />
      </Modal>
    </>
  );
  // UI component rendering :: end
}
