export const UserRole = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  WORKER: "WORKER",
  CUSTOMER: "CUSTOMER",
};

export const CustomPropertyType = {
  items: 0,
  itemTypes: 1,
  ProjectItems: 2,
  shipmentItems: 3,
};

export const CustomPropertyAddType = {
  text: 0,
  date: 1,
  number: 2,
};

export const DEfaultOPtion = {
  fail: 0,
  pass: 1,
  na: 2,
};
export const processFormsTypes = {
  PassFailForm: 0,
  PropertyTextForm: 1,
  PropertyDateForm: 2,
  PropertyNumberForm: 3,
  GpsForm: 4,
  AddProjectForm: 5,
  RemoveProject: 7,
  SignatureForm: 8,
  ScanTag: 9,
};

export const timeZoneIANA = "america/new_york";

export const defaultDateTimeFormat = "YYYY-MM-DD";
export const displayTimeFormat = "MM/DD/YYYY";
// export const displayTimeFormat = "DD/MM/YYYY";

export const displayTimeFormatWithTIme = "MM/DD/YYYY h:mm A";

export const SubscribedRoutes = ["/forney-vault", "/ncdot-hicams"];

export const NotificationTab = {
  Inbox: 0,
  ArchiveList: 1,
  ConfigurationsAdmin: 2,
  GroupsAdmin: 3,
};
