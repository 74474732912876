import { API } from "aws-amplify";

const RESTPost = (path, bodyData) => {
  try {
    return API.post("api19a88d9c", path, {
      body: bodyData,
    });
  } catch (e) {
    return { error: "Something went wrong" };
  }
};
const RESTExportPost = (path, bodyData) => {
  try {
    return API.post("api19a88d9c", path, {
      body: bodyData,
      responseType: "arraybuffer", // For binary data handling
      // fetchOptions: {
      //   headers: { "Content-Type": "application/json" },
      // },
    });
  } catch (e) {
    console.log("export post error", e);

    return { error: "Something went wrong" };
  }
};

const RESTPut = (path, bodyData) => {
  try {
    return API.put("api19a88d9c", path, {
      body: bodyData,
    });
  } catch (e) {
    return { error: "Something went wrong" };
  }
};

const RESTDelete = (path, bodyData) => {
  try {
    return API.del("api19a88d9c", path, {
      body: bodyData,
    });
  } catch (e) {
    return { error: "Something went wrong" };
  }
};
export const RESTGet = (path, options = {}) => {
  return API.get("api19a88d9c", path, {
    queryStringParameters: options,
  });
};

export const RESTCreateUser = (data) => {
  const path = "/organizationusers";
  return RESTPost(path, data);
};

export const RESTCDeleteUser = (data) => {
  const path = "/users";
  return RESTPost(path, data);
};

export const deleteCustomProperty = (data) => {
  const path = "/customproperties";
  return RESTDelete(path, data);
};

export const deleteProcess = (data) => {
  const path = "/processes";
  return RESTDelete(path, data);
};
export const deleteItemTypes = (data) => {
  const path = "/itemtypes";
  return RESTDelete(path, data);
};
export const orderProcessStepsAndPhase = (data) => {
  const path = "/processes";
  return RESTPut(path, data);
};

export const uniqueNumberCheck = (data) => {
  const path = "/uniquenumber";
  return RESTPost(path, data);
};

export const deleteItems = (data) => {
  const path = "/items";
  return RESTDelete(path, data);
};

export const itemTypesCSVUploadValidate = (organizationId, projectId, excelData) => {
  const path = "/importcsv/validate";
  return RESTPost(path, { excelData, organizationId, projectId });
};
export const itemTypesCSVUploadInsert = (excelData, organizationId, projectId) => {
  const path = "/importcsv/insert";
  return RESTPost(path, { excelData, organizationId, projectId });
};
export const itemsCSVUpload = (organizationId, excelData, userId) => {
  const path = "/items/importcsv/validate";
  return RESTPost(path, { excelData, organizationId, userId });
};
export const itemsCSVInsert = (excelData, organizationId, userId) => {
  const path = "/items/importcsv/insert";
  return RESTPost(path, { excelData, organizationId, userId });
};
export const DuplicateItemTypes = (data) => {
  const path = "/itemtypes/duplicate";
  return RESTPost(path, data);
};
export const deleteProjects = (data) => {
  const path = "/projects";
  return RESTDelete(path, data);
};

export const deleteCustomer = (data) => {
  const path = "/customer";
  return RESTDelete(path, data);
};

export const uniqueNameCheck = (data) => {
  const path = "/uniquename";
  return RESTPost(path, data);
};

// export const listCustomProperties = (params) => {
//   const path = "/customproperties";
//   return RESTGet(path, params);
// };
export const listCustomPropertiesSearch = (params) => {
  const path = "/customproperties/search";
  return RESTGet(path, params);
};
// export const listProcess = (params) => {
//   const path = "/processes";
//   return RESTGet(path, params);
// };
export const listProcessSearch = (params) => {
  const path = "/processes/search";
  return RESTGet(path, params);
};
export const processSnapShot = (params) => {
  const path = "/processes/update";
  return RESTPut(path, params);
};

// export const listItemsTypes = (params) => {
//   const path = "/itemtypes";
//   return RESTGet(path, params);
// };
export const listItemsTypesSearch = (params) => {
  const path = "/itemtypes/search";
  return RESTGet(path, params);
};
// export const listItems = (params) => {
//   const path = "/items";
//   return RESTGet(path, params);
// };
export const listItemsSearch = (params) => {
  const path = "/items/search";
  return RESTGet(path, params);
};
export const listOfBoldReports = (params) => {
  const path = "/reports/list";
  return RESTPost(path, params);
};
// export const listProjects = (params) => {
//   const path = "/projects";
//   return RESTGet(path, params);
// };
export const listProjectsSearch = (params) => {
  const path = "/projects/search";
  return RESTGet(path, params);
};
export const fetchForneyResult = (params) => {
  const path = "/forneyvault";
  return RESTGet(path, params);
};

export const customPropertiesSVUpload = (organizationId, csv, userId) => {
  const path = "/customproperties/importcsv";
  let formData = new FormData();
  formData.append("csvFile", csv);
  formData.append("organizationId", organizationId);
  formData.append("userId", userId);

  return RESTPost(path, formData);
};
export const forneyVaultAPI = (data) => {
  const path = "/forneyvault/cylinder";
  return RESTPost(path, data);
};

export const completedProcessSteps = (data) => {
  const path = "/items/processes";
  return RESTPost(path, data);
};

export const deleteShipment = (data) => {
  const path = "/shipments";
  return RESTDelete(path, data);
};
export const duplicateShipment = (data) => {
  const path = "/shipments/duplicate";
  return RESTPost(path, data);
};
export const dashboardEmbeddedURL = (data) => {
  const path = "/dashboard";
  return RESTGet(path, data);
};

export const processReopen = (params) => {
  const path = "/processes/reopen";
  return RESTPut(path, params);
};
export const processSnapShots = (params) => {
  const path = "/processes/snapshot";
  return RESTPost(path, params);
};
export const forneyvaultTestResult = (params) => {
  const path = "/forneyvault/testresult";
  return RESTGet(path, params);
};
export const forneyvaultTestLogs = (params) => {
  const path = "/forneyvault/logs";
  return RESTGet(path, params);
};
export const forneyvaultStrengthTest = (params) => {
  const path = "/forneyvault/strengthTest";
  return RESTGet(path, params);
};
export const refidGo = () => {
  const path = "/rfid-go";
  return RESTGet(path);
};
export const triggerAPI = (params) => {
  const path = "/hicamps";
  return RESTPost(path, params);
};
export const resendAPI = (params) => {
  const path = "/hicamps/resend";
  return RESTPut(path, params);
};
export const associateApi = (params) => {
  const path = "/documents/associate";
  return RESTPost(path, params);
};
export const deleteMultipleItems = (params) => {
  const path = "/items/batch-delete";
  return RESTDelete(path, params);
};
export const deleteMultipleDocumentsAPI = (params) => {
  const path = "/documents/deleteall";
  return RESTDelete(path, params);
};

export const getDashboardURL = (data) => {
  const path = "/dashboard";
  return RESTGet(path, data);
};

export const logDashboardView = (data) => {
  const path = "/dashboard/log";
  return RESTPost(path, data);
};
export const exportProjectAPI = (params) => {
  const path = "/projects/export";
  return RESTPost(path, params);
};
export const exportItemsAPI = (params) => {
  const path = "/items/export";
  return RESTPost(path, params);
};

export const getMember = (id) => {
  const params = {
    groupId: id,
  };
  const path = "/notifications/groupUsers";
  return RESTGet(path, params);
};

export const createnotification = (data) => {
  const path = "/notifications";
  return RESTPost(path, data);
};

export const searchNotificationsUser = (params) => {
  const path = "/notifications/search";
  return RESTGet(path, params);
};
export const getItemsPictures = (params) => {
  const path = "/items/pictures";
  return RESTGet(path, params);
};

export const createBucketList = (params) => {
  const path = "/rfidgo/bucket";
  return RESTPost(path, params);
};

export const fetchProjects = () => {
  const path = "/rfidgo/projects";
  return RESTGet(path);
};

export const deleteBucket = (params) => {
  const path = "/rfidgo/bucket";
  return RESTDelete(path, params);
};

export const refreshBucket = (params) => {
  const path = "/rfidgo/bucket/refresh";
  return RESTPost(path, params);
};

export const fetchInventoryData = (params) => {
  const path = "/rfidgo";
  return RESTGet(path, params);
};
