//Delete Document
// DeleteDocument.jsx
import React, { useState } from "react"; // react hooks import
import { Button, message, Modal } from "antd"; // antd components import
import { Formik, Form } from "formik"; // formik components import
import { DeleteOutlined } from "@ant-design/icons"; // ant design icons import
import { CommonError } from "utils/Helper/CommonError"; // common error import
import {
  deleteDocumentAttachments,
  deleteDocuments,
  searchDocumentsCustom,
} from "utils/Actions"; // graphql queries import
import { deleteMultipleDocumentsAPI } from "utils/RESTApi"; // import deleteMultipleDocumentsAPI from RESTApi

export default function DeleteDocument(props) {
  const {
    setDeleteDoc,
    deleteDoc,
    handleDelete = () => {},
    documentId,
    currentIdToken,
    deleteAttachments,
    documentToDelete,
    userOrganizationId,
    multiDelete = false,
    idArray = [],
    onDeleteSuccess = () => {}, // Add new callback prop
  } = props; // Destructure props

  const [loading, setLoading] = useState(false); // loading state
  const error = { message: "Folder is not empty" }; // error message

  // delete document :: begin
  const deleteQuery = async () => {
    let input = {
      id: documentId,
    };

    try {
      setLoading(true);
      // delete document from graphql
      await deleteDocuments(input, currentIdToken);

      // delete document attachments if required
      if (deleteAttachments) {
        await Promise.all(
          deleteAttachments?.map(
            async (attachment) =>
              await deleteDocumentAttachments(
                { id: attachment?.id },
                currentIdToken
              )
          )
        );
      }

      message.success("Document successfully deleted");
      onDeleteSuccess(); // Call callback on successful deletion
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
    }
    handleDelete(); // Call callback on delete button click
  };
  // delete document :: end

  // delete multiple documents :: begin
  const deleteDocument = async () => {
    const document = documentToDelete[0]?.fileType === "Folder";
    if (!multiDelete) {
      if (document) {
        let filter = {
          organizationID: { eq: userOrganizationId },
          isDeleted: { ne: true },
          parentId: { eq: documentToDelete[0]?.id },
          isFromMobile: { ne: true },
        };

        const sort = {};

        const response = await searchDocumentsCustom(
          filter,
          sort,
          999,
          null,
          currentIdToken
        );

        if (response?.items?.length === 0) {
          await deleteQuery();
        } else {
          CommonError(error);
          setDeleteDoc(false);
        }
      } else {
        await deleteQuery();
      }
    } else {
      const error = { message: "One or more folders can't be deleted..." };
      const documentIds = idArray?.map((doc) => doc.id);
      const folderId = idArray
        ?.filter((doc) => doc?.fileType === "Folder")
        ?.map((doc) => doc.id)
        .flat();

      const folderData = await Promise.all(
        folderId?.map(async (id) => {
          let filter = {
            organizationID: { eq: userOrganizationId },
            isDeleted: { ne: true },
            parentId: { eq: id },
            isFromMobile: { ne: true },
          };

          const sort = {};

          const response = await searchDocumentsCustom(
            filter,
            sort,
            999,
            null,
            currentIdToken
          );

          return response?.items;
        })
      );

      const folderDataId = folderData
        .flat()
        .map((folder) => folder.parentId)
        .flat();

      // Folders with documents
      const filterDataId = [...new Set(folderDataId)];

      // IDs of document to delete
      const filterDocumentId = documentIds
        ?.filter((item) => !filterDataId.includes(item))
        .flat();

      try {
        setLoading(true);
        if (filterDocumentId?.length > 0) {
          await deleteMultipleDocumentsAPI({
            documents: filterDocumentId,
          });

          if (filterDataId?.length > 0) {
            CommonError(error);
          }
          onDeleteSuccess(); // Call callback on successful deletion
          message.success("Selected Documents are successfully deleted");
        } else {
          CommonError({ message: "All folders contains data" });
        }
      } catch (error) {
        CommonError(error);
      } finally {
        setDeleteDoc(false);
        setLoading(false);
      }
    }
  };
  // delete multiple documents :: end

  // UI component rendering :: begins
  return (
    <Modal
      title={
        !multiDelete ? documentToDelete[0]?.name : "Delete multiple documents"
      }
      width={350}
      centered
      className="modal"
      maskClosable={true}
      open={deleteDoc}
      onCancel={() => {
        setDeleteDoc(false);
      }}
      footer={[
        <Button
          key="delete"
          type="primary"
          danger
          onClick={() => deleteDocument()}
          loading={loading}
          disabled={loading}
        >
          Delete
        </Button>,
      ]}
    >
      <Formik>
        <Form id="deleteDocument" className="delete-modal">
          <div>
            <div className="delete-icon">
              <DeleteOutlined />
            </div>
            <p>
              Are you sure you want to delete{" "}
              {!multiDelete
                ? documentToDelete[0]?.name
                : `${idArray?.length} Documents`}
              ?
            </p>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
  // UI component rendering :: ends
}
