import React, { useState, useCallback, useEffect } from "react";
import { Row, Button, Grid, Input, Space, message, Dropdown } from "antd";
import { useSelector } from "react-redux";
import { getModuleAccess } from "store/slices/moduleAccessSlice";
import SearchableTableWithSorting from "Common/Components/DataTable/DataTableWithSorting";
import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  DownSquareOutlined,
  MedicineBoxOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import NotificationDrawer from "./NotificationsDetails/NotificationDrawer";
import { NotificationTab } from "utils/Constants";
import "./notifications.less";
import CreateNotificationGroup from "./ManageNotifications/CreateGroup";
import CreateNotification from "./ManageNotifications/CreateNotification";
import {
  dateTimeConversionBasedOnTimeZone,
  debounceFn,
} from "utils/Helper/commonMethods";
import {
  searchNotificationGroups,
  searchNotifications,
  searchNotificationsUsers,
  updateNotificationsUsers,
} from "utils/Actions";
import {
  getCurrentIdToken,
  getOrganizationId,
  isSuperAdmin as SUPERADMIN,
  isAdmin as Organizationadmin,
  getCurrentUser,
  isAdmin,
} from "store/slices/loginSlice";
import { CommonError } from "utils/Helper/CommonError";
import DeleteModal from "Common/Components/ReusebaleModals/DeleteModal";
import { handleDelete } from "./Common";
import { searchNotificationsUser } from "utils/RESTApi";
import { useNavigate, useSearchParams } from "react-router-dom";
const { Search } = Input;
const { useBreakpoint } = Grid;
// Constants for text truncation
const PREVIEW_MAX_LENGTH = 60;
export default function Notifications() {
  const userOrganizationId = useSelector(getOrganizationId);
  const currentIdToken = useSelector(getCurrentIdToken);
  const isSuperAdmin = useSelector(SUPERADMIN);
  const UserIsAdmin = useSelector(Organizationadmin);
  const userID = useSelector(getCurrentUser);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [createNotificationGroupVisible, setCreateNotificationGroupVisible] =
    useState(false);
  const [createNotificationConfigVisible, setCreateNotificationConfigVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationsToDelete, setNotificationsToDelete] = useState([]);
  const [activeTab, setActiveTab] = useState(NotificationTab.Inbox);
  const [notificationGroupEdit, setNotificationGroupEdit] = useState(false);
  const [notificationConfigEdit, setNotificationConfigEdit] = useState(false);
  const [viewType, setViewType] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const screens = useBreakpoint();
  const [scrollLoading, setScrollLoading] = useState(false);
  const [notificationGroups, setNotificationGroups] = useState([]);
  const [configData, setConfigData] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("0");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("name");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [dynamicUrl] = useSearchParams();
  const [fetchLatest, setFetchLatest] = useState(false);
  const searchParams = dynamicUrl.get("notifications_id");
  const { hasReadAccess, hasCreateAccess, hasUpdateAccess, hasDeleteAccess } =
    useSelector((state) => getModuleAccess(state, "Notifications"));
  const navigate = useNavigate();
  //handle the bulk delete
  const handleBulkDelete = () => {
    if (selectedNotifications.length === 0) {
      message.warning("Please select notifications to delete");
      return;
    }
    setNotificationsToDelete(selectedNotifications);
    setDeleteModalVisible(true);
  };
  // sorting dropdown options
  const items = [
    {
      label: "All",
      key: "0",
      onClick: () => setSelectedFilter("0"),
    },
    {
      label: "Read",
      key: "1",
      onClick: () => setSelectedFilter("1"),
    },
    {
      label: "Unread",
      key: "2",
      onClick: () => setSelectedFilter("2"),
    },
  ];
  // Helper function to truncate text for descriptions
  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Handle undefined or null text
    return text.length <= maxLength
      ? text
      : `${text.substring(0, maxLength)}...`;
  };
  // fetch notifications and update state
  // Sort function
  const handleSort = async (columnKey, order) => {
    let sortOrder = order === "ascend" ? "asc" : "desc";
    setSortColumn(columnKey);
    setSortOrder(sortOrder);
    setNextToken(null);
    if (activeTab === NotificationTab.ConfigurationsAdmin) {
      await fetchConfigData(null, searchQuery, sortOrder, columnKey);
    } else if (activeTab === NotificationTab.GroupsAdmin) {
      await fetchGroupsData(null, searchQuery, sortOrder, columnKey);
    } else {
      await fetchInboxData(null, searchQuery, sortOrder, columnKey);
    }
  };
  // row selection
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedNotifications(selectedRows);
      setSelectedKeys(selectedRowKeys);
    },
  };
  // inbox columns for admin view (isAdmin)
  const inboxColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <span className="name">{record?.notification?.name || "N/A"}</span>
      ),
      sorter: (a, b) => {
        return a?.notification?.name.localeCompare(b?.notification?.name);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "notification.name.keyword"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("notification.name.keyword", "ascend");
          } else if (isDescendingClick) {
            handleSort("notification.name.keyword", "descend");
          }
        },
      }),
    },
    {
      title: "Date Received",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) =>
        dateTimeConversionBasedOnTimeZone(
          text || new Date(),
          "YYYY-MM-DD HH:mm:ss A"
        ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "createdAt"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("createdAt", "ascend");
          } else if (isDescendingClick) {
            handleSort("createdAt", "descend");
          }
        },
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
      render: (_, record) => (
        <Button
          type="link"
          className="preview-text"
          onClick={() => {
            setSelectedNotification(record);
            setDrawerOpen(true);
            if (
              activeTab === NotificationTab.ArchiveList ||
              activeTab === NotificationTab.Inbox
            ) {
              navigate(`?notifications_id=${record?.notificationId}`, {
                replace: true,
              });
            }
          }}
        >
          {truncateText(
            record?.notification?.description || "",
            PREVIEW_MAX_LENGTH
          )}
        </Button>
      ),
    },
    {
      title: "Read",
      dataIndex: "isRead",
      key: "isRead",
      render: (isRead) => (
        <div className="read-status">
          {isRead && (
            <span className="read-check">
              <CheckOutlined />
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          {hasUpdateAccess && (
            <Button
              type="link"
              onClick={() => handleArchiveToggle(record)}
              className="text-primary fs-14 p-0 mr-20"
            >
              {activeTab === NotificationTab.ArchiveList
                ? "Unarchive"
                : "Archive"}
            </Button>
          )}
          {hasDeleteAccess && (
            <Button
              type="link"
              danger
              onClick={() => {
                setNotificationsToDelete(record);
                setDeleteModalVisible(true);
              }}
              className="p-0"
            >
              <DeleteOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];
  // config columns for admin view (isAdmin)
  const configColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="name">{text}</span>,
      sorter: (a, b) => {
        return a?.name.localeCompare(b?.name);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "name"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("name", "ascend");
          } else if (isDescendingClick) {
            handleSort("name", "descend");
          }
        },
      }),
    },
    {
      title: "Receiver",
      dataIndex: "receiver",
      key: "receiver",
      render: (_, record) => (
        <Space>
          {hasUpdateAccess && (
            <Button
              type="link"
              className="text-primary fs-14"
              onClick={() => {
                setViewType("receiverList");
                setSelectedNotification(record);
                setDrawerOpen(true);
              }}
            >
              View List
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <Button
          type="link"
          className="preview-text"
          onClick={() => {
            setSelectedNotification(record);
            setDrawerOpen(true);
          }}
        >
          {truncateText(text || "", PREVIEW_MAX_LENGTH)}
        </Button>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) =>
        dateTimeConversionBasedOnTimeZone(text, "YYYY-MM-DD HH:mm:ss A"),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "createdAt"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("createdAt", "ascend");
          } else if (isDescendingClick) {
            handleSort("createdAt", "descend");
          }
        },
      }),
    },
    {
      title: "Modified Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) =>
        dateTimeConversionBasedOnTimeZone(text, "YYYY-MM-DD HH:mm:ss A"),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "updatedAt"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("updatedAt", "ascend");
          } else if (isDescendingClick) {
            handleSort("updatedAt", "descend");
          }
        },
      }),
    },
  ];
  //group columns for admin view (isAdmin)
  const groupColumns = [
    {
      title: "Group Name",
      dataIndex: "groupname",
      key: "groupname",
      render: (text, value) => (
        <Button
          type="link"
          className="preview-text"
          onClick={() => {
            setSelectedNotification(value);
            setDrawerOpen(true);
            console.log("Group value: " + text);
          }}
        >
          {value.name}
        </Button>
        // <span className="name">{value.name}</span>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "name"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("name", "ascend");
          } else if (isDescendingClick) {
            handleSort("name", "descend");
          }
        },
      }),
    },
    {
      title: "Member Count",
      dataIndex: "member_count",
      key: "member_count",
      render: (text, value) => (
        <span className="membercount">{value.memberIds?.length || 0}</span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "25%",
      render: (text, record) => (
        <span>{truncateText(text || "", PREVIEW_MAX_LENGTH)}</span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) =>
        dateTimeConversionBasedOnTimeZone(text, "YYYY-MM-DD HH:mm:ss A"),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "createdAt"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("createdAt", "ascend");
          } else if (isDescendingClick) {
            handleSort("createdAt", "descend");
          }
        },
      }),
    },
    {
      title: "Modified Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) =>
        dateTimeConversionBasedOnTimeZone(text, "YYYY-MM-DD HH:mm:ss A"),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortColumn === "updatedAt"
          ? sortOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("updatedAt", "ascend");
          } else if (isDescendingClick) {
            handleSort("updatedAt", "descend");
          }
        },
      }),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          {hasUpdateAccess && (
            <Button
              type="link"
              className="text-primary fs-14 p-0 mr-20"
              onClick={() => {
                setCreateNotificationGroupVisible(true);
                setNotificationGroupEdit(true);
                setSelectedNotification(record);
              }}
            >
              Edit
            </Button>
          )}
          {hasDeleteAccess && (
            <Button
              type="link"
              danger
              className="p-0"
              onClick={() => {
                setNotificationsToDelete(record);
                setDeleteModalVisible(true);
              }}
            >
              <DeleteOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];
  // In the fetchInboxData function, modify it to filter based on isArchived
  const fetchInboxData = async (page, searchQuery, sortColumn, sortOrder) => {
    setLoading(true);
    const params = {
      organizationId: userOrganizationId,
      keyword: searchQuery,
      limit: 20,
      sortBy: sortOrder,
      sortDir: sortColumn,
      searchAfter: JSON.stringify(page) || "",
      isArchive: activeTab === NotificationTab.ArchiveList,
      userId: userID?.sub,
      isDeleted: false,
    };
    try {
      const response = await searchNotificationsUser(params);
      if (searchQuery?.length >= 0 && !nextToken) {
        setNotifications(response?.data?.data);
      } else if (nextToken) {
        setNotifications((prevData) => [...prevData, ...response?.data?.data]);
      }
      if (response?.data?.data?.length === 20) {
        setNextToken(
          response?.data?.data[+response?.data?.data.length - 1].sort
        );
      } else {
        setNextToken(null);
      }
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
    }
  };
  // fetch configured notifications
  const fetchConfigData = async (
    nextToken,
    searchQuery,
    sortOrder,
    sortColumn,
    id
  ) => {
    setLoading(true);
    try {
      const filter = {
        organizationID: { eq: userOrganizationId },
        // id: { eq: searchParams },
        ...(searchQuery
          ? {
              or: {
                name: { matchPhrasePrefix: `${searchQuery}` },
              },
            }
          : {}),
        isDeleted: { ne: true },
      };
      const limit = 20;
      const sort = {};
      if (sortOrder && sortColumn) {
        sort.field = sortColumn;
        sort.direction = sortOrder;
      }
      const response = await searchNotifications(
        filter,
        sort,
        limit,
        nextToken,
        currentIdToken
      );
      if (searchParams) {
        if (response?.items?.length) {
          setSelectedNotification(response?.items[0]);
        } else {
          navigate(window.location.pathname, { replace: true });
          setDrawerOpen(false);
        }
      } else {
        if (searchQuery?.length >= 0 && !nextToken) {
          setConfigData(response?.items);
        } else {
          setConfigData((prevData) => [...prevData, ...response?.items]);
        }
      }
      if (response?.items?.length === 20) {
        setNextToken(response?.nextToken);
      } else {
        setNextToken(null);
      }
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
      setScrollLoading(false); // scroll loading removed
    }
  };
  const fetchGroupsData = async (
    nextToken,
    searchQuery,
    sortOrder,
    sortColumn
  ) => {
    setLoading(true);
    let groupId = selectedNotification?.id;
    try {
      const filter = {
        organizationID: { eq: userOrganizationId },
        ...(searchQuery
          ? {
              or: {
                name: { matchPhrasePrefix: `${searchQuery}` },
              },
            }
          : {}),
        ...(fetchLatest
          ? {
              id: {
                eq: groupId,
              },
            }
          : {}),
      };
      const limit = 20;
      const sort = {};
      if (sortOrder && sortColumn) {
        sort.field = sortColumn;
        sort.direction = sortOrder;
      }
      const response = await searchNotificationGroups(
        filter,
        sort,
        limit,
        nextToken,
        currentIdToken
      );
      if (searchQuery?.length >= 0 && !nextToken) {
        setNotificationGroups(response?.items);
      } else {
        setNotificationGroups((prevData) => [...prevData, ...response?.items]);
      }
      if (response?.items?.length === 20) {
        setNextToken(response?.nextToken);
      } else {
        setNextToken(null);
      }
      if (fetchLatest) {
        setSelectedNotification(response?.items[0]);
      }
      setLoading(false);
    } catch (error) {
      CommonError(error);
      setLoading(false);
    } finally {
      setScrollLoading(false); // scroll loading false
    }
  };
  useEffect(() => {
    if ((fetchLatest && drawerOpen) || createNotificationConfigVisible) {
      setTimeout(() => {
        fetchGroupsData(null, searchQuery);
      }, 2500);
    }
  }, [fetchLatest]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     fetchGroupsData(null, searchQuery);
  //   }, 2500);
  // }, [createNotificationConfigVisible]);
  useEffect(() => {
    if (createNotificationConfigVisible) {
      setFetchLatest(false);
    }
    if (!createNotificationGroupVisible && !drawerOpen) {
      setFetchLatest(false);
    }
  }, [createNotificationGroupVisible]);
  useEffect(() => {
    setFetchLatest(false);
  }, [activeTab, drawerOpen]);
  useEffect(() => {}, []);
  const fetchNotificationsUser = async () => {
    // try catch added
    try {
      const filter = {
        organizationID: { eq: userOrganizationId },
        notificationId: { eq: searchParams },
        isDeleted: { ne: true },
        userId: { eq: userID?.sub },
      };
      const sort = {};
      const limit = 20;
      const response = await searchNotificationsUsers(
        filter,
        sort,
        limit,
        undefined,
        currentIdToken
      );
      if (searchParams && response?.items?.length) {
        setSelectedNotification(response?.items[0]);
      } else {
        navigate(window.location.pathname, { replace: true });
        setDrawerOpen(false);
      }
    } catch (error) {
      CommonError(error);
      setLoading(false); // loading false
    } finally {
      setScrollLoading(false); // loading false
    }
  };
  // search handle :: begin
  const handleSearch = (value) => {
    setNextToken(null);
    setSearchQuery(value?.target?.value?.trim());
  };
  // Update the debouncedHandleSearch function
  const debouncedHandleSearch = debounceFn(handleSearch, 1500);
  // set scroll on page end :: begins
  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextToken) {
      setScrollLoading(true);
      if (activeTab === NotificationTab.ConfigurationsAdmin) {
        fetchConfigData(nextToken, searchQuery, sortOrder, sortColumn);
      } else if (activeTab === NotificationTab.GroupsAdmin) {
        fetchGroupsData(nextToken, searchQuery, sortOrder, sortColumn);
      } else {
        fetchInboxData(nextToken, searchQuery);
      }
    }
  };
  // get filter notifications based on isAdmin, !isAdmin and active tab
  const getFilteredNotifications = useCallback(() => {
    // Handle non-admin filtering
    const archiveFiltered = notifications?.filter((notification) =>
      activeTab === NotificationTab.ArchiveList
        ? notification.isArchived
        : !notification.isArchived
    );
    if (!archiveFiltered) return [];
    // Then filter by read status
    switch (selectedFilter) {
      case "1": // Read
        return archiveFiltered.filter((notification) => notification.isRead);
      case "2": // Unread
        return archiveFiltered.filter((notification) => !notification.isRead);
      default: // All
        return archiveFiltered;
    }
  }, [notifications, activeTab, selectedFilter]);
  // handle the columns render based on isAdmin and active tab
  const getTableColumns = () => {
    if (activeTab === NotificationTab.ConfigurationsAdmin) {
      return configColumns;
    } else if (activeTab === NotificationTab.GroupsAdmin) {
      return groupColumns;
    } else {
      return inboxColumns;
    }
  };
  // handle the table data render based on isAdmin and active tab
  const getTableData = () => {
    if (activeTab === NotificationTab.ConfigurationsAdmin) {
      return configData || [];
    } else if (activeTab === NotificationTab.GroupsAdmin) {
      return notificationGroups || [];
    } else if (
      activeTab === NotificationTab.Inbox ||
      activeTab === NotificationTab.ArchiveList
    ) {
      return getFilteredNotifications();
    } else {
      return notifications;
    }
  };
  const getFilterLabel = () => {
    switch (selectedFilter) {
      case "1":
        return "Read";
      case "2":
        return "Unread";
      default:
        return "All";
    }
  };
  // handle the tab change
  const handleTabClick = (tab) => {
    if (tab === activeTab) return;
    setActiveTab(tab);
    setSelectedNotifications([]);
    setSelectedKeys([]);
    setSearchQuery("");
    setSearchValue("");
  };
  // handle archive change
  const handleArchiveToggle = async (record) => {
    setLoading(true);
    try {
      const input = {
        id: record.id,
        organizationID: record.organizationID,
        isArchived: !record.isArchived,
        notificationId: record.notificationId,
        userId: record.userId,
      };
      // call update notifications user mutation
      await updateNotificationsUsers(input, currentIdToken);
      message.success(
        `Notification ${
          !record.isArchived ? "archived" : "unarchive"
        } successfully`
      );
      // Refresh the notifications list
      setTimeout(async () => {
        await fetchInboxData(nextToken, searchQuery);
      }, 2500); // timer increase
    } catch (error) {
      message.error(
        `Failed to ${record.isArchived ? "unarchive" : "archive"} notification`
      );
      setLoading(false);
    }
  };
  // Handle bulk archive/unarchive
  const handleBulkArchive = async () => {
    // Check if any notifications are selected
    if (selectedNotifications.length === 0) {
      message.warning("Please select notifications to archive");
      return;
    }
    setLoading(true);
    let successCount = 0;
    let failCount = 0;
    try {
      // Process each selected notification
      await Promise.all(
        selectedNotifications.map(async (notification) => {
          const input = {
            id: notification.id,
            organizationID: notification.organizationID,
            isArchived:
              activeTab === NotificationTab.ArchiveList ? false : true,
            notificationId: notification.notificationId,
            userId: notification.userId,
          };
          try {
            await updateNotificationsUsers(input, currentIdToken);
            successCount++;
          } catch (error) {
            message.error(
              "Bulk archive error for notification:",
              notification.id,
              error
            );
            failCount++;
          }
        })
      );
      // Show appropriate success/failure message
      if (successCount > 0) {
        const action =
          activeTab === NotificationTab.ArchiveList ? "unarchive" : "archived";
        message.success(
          `${successCount} notification${
            successCount !== 1 ? "s" : ""
          } ${action} successfully`
        );
      }
      if (failCount > 0) {
        message.error(
          `Failed to process ${failCount} notification${
            failCount !== 1 ? "s" : ""
          }`
        );
      }
      // Reset selections
      setSelectedNotifications([]);
      setSelectedKeys([]);
      // Refresh the notifications list
      if (
        activeTab === NotificationTab.Inbox ||
        activeTab === NotificationTab.ArchiveList
      ) {
        setTimeout(async () => {
          await fetchInboxData(nextToken, searchQuery);
        }, 2500); // timer increased
      }
    } catch (error) {
      message.error("Failed to process notifications");
      setLoading(false);
    }
  };
  //common function handle the fetching data
  const fetchNotificationData = (nextToken, searchQuery) => {
    if (activeTab === NotificationTab.ConfigurationsAdmin) {
      fetchConfigData(nextToken, searchQuery);
    } else if (activeTab === NotificationTab.GroupsAdmin) {
      fetchGroupsData(nextToken, searchQuery);
    } else {
      fetchInboxData(nextToken, searchQuery);
    }
  };
  // fetch data  :: begins
  useEffect(() => {
    fetchNotificationData(null, searchQuery);
    setSortColumn("");
    setSortOrder("");
    setNextToken(null);
  }, [searchQuery, userOrganizationId]);
  // fetch data :: end
  useEffect(() => {
    setSearchQuery("");
    setNotifications([]);
    setConfigData([]);
    setNotificationGroups([]);
    fetchNotificationData(null, searchQuery);
    setSortColumn("");
    setSortOrder("");
    setNextToken(null);
  }, [activeTab]);
  useEffect(() => {
    if (
      (activeTab === NotificationTab?.Inbox ||
        activeTab === NotificationTab?.ArchiveList) &&
      !drawerOpen
    ) {
      setTimeout(() => {
        fetchInboxData();
      }, 2500); // timer increased
    }
  }, [drawerOpen]);
  useEffect(() => {
    if (searchParams) {
      setDrawerOpen(true);
      fetchNotificationsUser();
    }
  }, [searchParams]);
  const closeDrawer = () => {
    navigate(window.location.pathname, { replace: true });
    setDrawerOpen(false);
    // fetchNotificationData(null, searchQuery);
    setSelectedNotification([]);
    setViewType(null);
  };
  const handleDeleteNotification = async () => {
    setLoading(true);
    if (
      Array.isArray(notificationsToDelete) &&
      notificationsToDelete.length > 1
    ) {
      // Handle bulk delete
      let successCount = 0;
      let failCount = 0;
      try {
        await Promise.all(
          notificationsToDelete.map(async (notification) => {
            try {
              await handleDelete(
                notification.id,
                activeTab,
                setDeleteLoading,
                currentIdToken,
                setDeleteModalVisible
              );
              successCount++;
            } catch (error) {
              message.error(
                "Bulk delete error for notification:",
                notification.id,
                error
              );
              failCount++;
            }
          })
        );
        if (successCount > 0) {
          message.success(
            `${successCount} notification${
              successCount !== 1 ? "s" : ""
            } deleted successfully`
          );
        }
        if (failCount > 0) {
          message.error(
            `Failed to delete ${failCount} notification${
              failCount !== 1 ? "s" : ""
            }`
          );
        }
        setSelectedNotifications([]);
        setSelectedKeys([]);
        setDeleteModalVisible(false);
      } catch (error) {
        message.error("Bulk delete operation error:", error);
        setLoading(false);
      }
    } else {
      try {
        // Handle single delete
        const notificationId = Array.isArray(notificationsToDelete)
          ? notificationsToDelete[0]?.id
          : notificationsToDelete?.id;
        await handleDelete(
          notificationId,
          activeTab,
          setDeleteLoading,
          currentIdToken,
          setDeleteModalVisible
        );
      } catch (error) {
        CommonError(error);
        setLoading(false);
      }
    }
    setNotificationsToDelete();
    // Refresh the notifications list
    setTimeout(async () => {
      await fetchNotificationData(nextToken, searchQuery);
    }, 2500);
  };
  const deleteMessage = `Are you sure you want to delete ${
    activeTab === NotificationTab.GroupsAdmin
      ? "notification group" + `"${notificationsToDelete?.name}"` + " "
      : activeTab === NotificationTab.ConfigurationsAdmin
      ? "notification configuration" +
        " " +
        `"${notificationsToDelete?.name}"` +
        " "
      : Array.isArray(notificationsToDelete) && notificationsToDelete.length > 1
      ? `these ${notificationsToDelete.length} notifications?`
      : "this notification?"
  }`;
  const deleteHeader = `Delete ${
    activeTab === NotificationTab.GroupsAdmin
      ? notificationsToDelete?.name
      : activeTab === NotificationTab.ConfigurationsAdmin
      ? notificationsToDelete?.name
      : Array.isArray(notificationsToDelete)
      ? "Notifications"
      : "Notification"
  }`;
  useEffect(() => {
    if (activeTab === NotificationTab.GroupsAdmin && !drawerOpen) {
      setLoading(true);
      setTimeout(async () => {
        fetchGroupsData(nextToken, searchQuery);
      }, 2500);
    }
  }, [drawerOpen]);
  return (
    <div className="background-white">
      <Row
        justify="space-between"
        className="page-action-wrapper notification-page-action-wrapper"
      >
        <Space>
          <Search
            className="searchbar w-300 m-0"
            placeholder="Search"
            style={{
              width: 300,
            }}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              debouncedHandleSearch(e);
            }}
          />
        </Space>
        <Row className="dashboard-action-btn-wrap mt-sm-20">
          {activeTab === NotificationTab.Inbox ||
          activeTab === NotificationTab.ArchiveList ? (
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottomRight"
              className="mt-10 mr-20"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {getFilterLabel()}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          ) : null}
          {!UserIsAdmin && !isSuperAdmin && (
            <>
              {hasUpdateAccess && (
                <Button
                  className="dark-primary-btn"
                  shape="round"
                  type="primary"
                  onClick={handleBulkArchive}
                  disabled={
                    selectedNotifications.length === 1 ||
                    selectedNotifications.length === 0
                  }
                >
                  <MedicineBoxOutlined />
                  {activeTab === NotificationTab.ArchiveList
                    ? "Bulk Unarchive"
                    : "Bulk Archive"}
                  {selectedNotifications.length > 0 &&
                    ` (${selectedNotifications.length})`}
                </Button>
              )}
              {hasDeleteAccess && (
                <Button
                  className="dark-danger-btn "
                  shape="round"
                  type="primary"
                  onClick={handleBulkDelete}
                  disabled={
                    selectedNotifications.length === 1 ||
                    selectedNotifications.length === 0
                  }
                  danger
                >
                  <DeleteOutlined /> Bulk Delete
                  {selectedNotifications.length > 0 &&
                    ` (${selectedNotifications.length})`}
                </Button>
              )}
            </>
          )}
          {/* Admin actions */}
          {(isSuperAdmin || isAdmin) && (
            <>
              {activeTab === NotificationTab.ConfigurationsAdmin &&
                hasCreateAccess && (
                  <Button
                    shape="round"
                    className="mr-10 dark-primary-btn"
                    type="primary"
                    onClick={() => setCreateNotificationConfigVisible(true)}
                  >
                    <PlusOutlined /> Create Notification
                  </Button>
                )}
              {activeTab === NotificationTab.GroupsAdmin && hasCreateAccess && (
                <Button
                  shape="round"
                  className="mr-10 dark-primary-btn"
                  type="primary"
                  onClick={() => setCreateNotificationGroupVisible(true)}
                >
                  <PlusOutlined /> Create Group
                </Button>
              )}
            </>
          )}
        </Row>
      </Row>
      <Row
        justify="space-between"
        className="page-action-wrapper notification-page-action-wrapper"
      >
        <Space>
          {/* Admin Access Buttons */}
          <div className="admin-notification mb-5">
            <Button
              className={`custom-button-left-radius ${
                activeTab === NotificationTab.Inbox ? "activeButton" : ""
              }`}
              disabled={loading}
              onClick={() => handleTabClick(NotificationTab.Inbox)}
            >
              Inbox
            </Button>
            <Button
              className={`${
                !UserIsAdmin && !isSuperAdmin
                  ? "custom-button-right-radius"
                  : "custom-border"
              }  ${
                activeTab === NotificationTab.ArchiveList ? "activeButton" : ""
              }`}
              disabled={loading}
              onClick={() => handleTabClick(NotificationTab.ArchiveList)}
            >
              Archive
            </Button>
            <Button
              className={`${
                !UserIsAdmin && !isSuperAdmin && "d-none"
              } custom-border ${
                activeTab === NotificationTab.ConfigurationsAdmin
                  ? "activeButton"
                  : ""
              }`}
              disabled={loading}
              onClick={() =>
                handleTabClick(NotificationTab.ConfigurationsAdmin)
              }
            >
              Configurations
            </Button>
            <Button
              className={`${
                !UserIsAdmin && !isSuperAdmin && "d-none"
              } custom-button-right-radius ${
                activeTab === NotificationTab.GroupsAdmin ? "activeButton" : ""
              }`}
              disabled={loading}
              onClick={() => handleTabClick(NotificationTab.GroupsAdmin)}
            >
              Groups
            </Button>
          </div>
        </Space>
        <Row className="dashboard-action-btn-wrap mt-sm-20">
          {/* Archive mail list button for admin */}
          {isSuperAdmin && activeTab === NotificationTab.Outbox && (
            <div>
              <Button shape="round" className="mr-10">
                <DownSquareOutlined /> Archive mail list
              </Button>
            </div>
          )}
        </Row>
      </Row>
      {/* Searchable Table with sorting and pagination */}
      <SearchableTableWithSorting
        rowKey="id"
        screens={screens}
        columns={getTableColumns()}
        data={getTableData()}
        rowSelection={rowSelection}
        hideSelection={isSuperAdmin}
        loadData={scrollLoading}
        loading={loading && !scrollLoading}
        className="mt-10"
        handleChange={() => {}}
        handleScroll={(e) => handelInfiniteScroll(e)}
      />
      {/* Notification Drawer for view notification data in drawer using props  */}
      <NotificationDrawer
        open={drawerOpen}
        onClose={closeDrawer}
        notificationData={selectedNotification}
        hasReadAccess={hasReadAccess}
        hasUpdateAccess={hasUpdateAccess}
        hasDeleteAccess={hasDeleteAccess}
        isAdmin={isSuperAdmin}
        loading={loading}
        activeTab={activeTab}
        viewType={viewType}
        setDeleteLoading={setDeleteLoading}
        userId={userID?.sub}
        fetchData={fetchLatest}
        openEditGroup={(val) => {
          setCreateNotificationGroupVisible(val[0]?.showModal);
          setNotificationGroupEdit(val[0]?.editGroup);
          setNotificationGroupEdit(val[0]?.data);
        }}
      />
      {/* Notification Delete Modal For Confirmations */}
      <DeleteModal
        isDelete={deleteModalVisible}
        header={deleteHeader}
        setIsDelete={setDeleteModalVisible}
        message={deleteMessage}
        handleSubmit={handleDeleteNotification}
        loadingdata={deleteLoading}
      />
      {/* Create Notification Group Modal for active tab is group */}
      <CreateNotificationGroup
        open={createNotificationGroupVisible}
        onCancel={() => {
          setCreateNotificationGroupVisible(false);
          setNotificationGroupEdit(false);
          setLoading(true);
          setTimeout(() => {
            fetchGroupsData(nextToken, searchQuery);
          }, 2500); // timer increased
        }}
        loading={loading}
        notificationDataEdit={notificationGroupEdit}
        notificationData={selectedNotification}
        handleCustomSubmit={() => setFetchLatest(true)}
      />
      {/* Create Notification  Modal for active tab is Configurations */}
      <CreateNotification
        open={createNotificationConfigVisible}
        onCancel={() => {
          setCreateNotificationConfigVisible(false);
          setNotificationConfigEdit(false);
          fetchConfigData(nextToken, searchQuery);
        }}
        fetchData={fetchLatest}
        loading={loading}
        notificationConfigDataEdit={notificationConfigEdit}
        notificationData={selectedNotification}
        handleCreateGroup={(val) => {
          // console.log("Val", val);
          setCreateNotificationGroupVisible(val);
        }}
      />
    </div>
  );
}
