import React, { useEffect, useState } from "react"; // React hooks import
import { Input, Modal, Button, Radio } from "antd"; // Ant design components import
import "./../Component.less"; // Component styles import

export default function SelectOrganization(props) {
  // props :: begins
  const {
    setDeleteOrganization,
    deleteOrganization,
    setEditOrganizationFlag,
    editOrganizationFlag,
    setItemModal,
    itemModal,
    organizationData: allOrganizationData,
    organizationSelect,
    nextToDashBoard,
    currentOrganization,
    setNextEditOrganizationFlag,
    setDuplicateOrganization,
    duplicateOrganization,
    setNextDuplicateOrganizationFlag,
    setIsDeleteModalVisible,
  } = props;
  // props :: ends

  // React hooks :: begins
  useEffect(() => {
    const sortedOrganizations = allOrganizationData?.sort((a, b) => {
      return a.name.localeCompare(b.name); // Sorting by 'name' in ascending order
    });

    setOrganizationData(sortedOrganizations);
  }, [allOrganizationData]);
  const { Search } = Input;

  const [organizationData, setOrganizationData] = useState(allOrganizationData);
  // React hooks :: ends

  // Function to handle search :: begins
  const handleSearch = (value) => {
    // Filter organizations based on the search term
    const filteredOrganizations = allOrganizationData.filter((organize) =>
      organize.name.toLowerCase().includes(value.toLowerCase())
    );
    setOrganizationData(filteredOrganizations);
  };
  // Function to handle search :: ends

  // Function to handle submit :: begins
  const handleSubmit = () => {
    if (setEditOrganizationFlag) {
      setEditOrganizationFlag(false);
    }
    if (setDuplicateOrganization) {
      setDuplicateOrganization(false);
    }

    if (setDeleteOrganization) {
      setDeleteOrganization(false);
    }

    if (editOrganizationFlag) setNextEditOrganizationFlag(true);
    if (duplicateOrganization) setNextDuplicateOrganizationFlag(true);
    if (deleteOrganization) setIsDeleteModalVisible(true);

    setItemModal(false);
    if (
      !editOrganizationFlag &&
      !duplicateOrganization &&
      !deleteOrganization
    ) {
      return nextToDashBoard();
    }
  };
  // Function to handle submit :: ends

  // UI component rendering :: begins
  return (
    <Modal
      title="Select Organization"
      width={350}
      centered
      maskClosable={true}
      className="modal mb-10 one-btn-footer organizationModal"
      open={
        itemModal ||
        editOrganizationFlag ||
        duplicateOrganization ||
        deleteOrganization
      }
      onCancel={() => {
        if (setEditOrganizationFlag) setEditOrganizationFlag(false);
        if (setDuplicateOrganization) setDuplicateOrganization(false);
        if (setDeleteOrganization) setDeleteOrganization(false);
        setItemModal(false);
      }}
      footer={null}
    >
      {/* Sticky Search Bar */}
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: "9999",
          backgroundColor: "#fff", // Ensure it's not overlapped by content
          paddingBottom: "0px", // Optional space between search and list
          marginBottom: "-4px",
        }}
      >
        <Search
          className="searchbar"
          placeholder="Search"
          style={{
            width: 305,
          }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div
        className="mb-10"
        style={{
          maxHeight: "650px", // Adjust height
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        {organizationData?.map((organize) => (
          <div className="mb-10" key={organize?.id}>
            <Radio
              checked={organize?.id === currentOrganization?.id}
              onChange={() => organizationSelect(organize)}
            >
              {organize?.name}
            </Radio>
          </div>
        ))}
      </div>

      {/* Sticky "Next" Button */}
      <div
        style={{
          position: "sticky",
          bottom: "-1.5rem",
          left: "-1rem",
          right: "-1rem",
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "5px 0px",
          textAlign: "right",
          backgroundColor: "#fff", // Ensure it's not overlapped
        }}
      >
        <Button
          key="submit"
          type="primary"
          shape="round"
          htmlType="submit"
          className="w-100"
          onClick={() => handleSubmit()}
          disabled={!currentOrganization}
        >
          {deleteOrganization ? "Delete" : "Next"}
        </Button>
      </div>
    </Modal>
  );
  // UI component rendering :: ends
}
