import { message } from "antd";
import {
  deleteNotificationGroups,
  deleteNotifications,
  updateNotifications,
  updateNotificationsUsers,
} from "utils/Actions";
import { NotificationTab } from "utils/Constants";
import { CommonError } from "utils/Helper/CommonError";

// common function to handle delete operation for notifications
export const handleDelete = async (
  id,
  activeTab,
  setDeleteLoading,
  currentIdToken,
  setDeleteModalVisible
) => {
  try {
    setDeleteLoading(true);
    // Early return if no notifications to delete
    if (!id) {
      message.warning("No notification selected for deletion");
      return;
    }
    let successMessage = "";
    let input = {};

    // handle delete operation based on active tab
    switch (activeTab) {
      case NotificationTab.Inbox:
      case NotificationTab.ArchiveList:
        input = {
          id: id,
          isDeleted: true,
        };
        try {
          await updateNotificationsUsers(input, currentIdToken);
          successMessage = "Notification moved to trash successfully";
        } catch (error) {
          console.error("Error soft deleting notification:", error);
          throw error;
        }
        break;

      case NotificationTab.ConfigurationsAdmin:
        input = {
          id: id,
          isDeleted: true,
        };
        await updateNotifications(input, currentIdToken);
        successMessage = "Notification configuration deleted successfully";
        break;

      case NotificationTab.GroupsAdmin:
        input = {
          id: id,
        };
        await deleteNotificationGroups(input, currentIdToken);
        successMessage = "Notification group deleted successfully";
        break;

      default:
        throw new Error("Invalid tab selection for delete operation");
    }

    message.success(successMessage);
    setDeleteModalVisible(false);
    setDeleteLoading(false);
  } catch (error) {
    console.error("Delete operation error:", error);
    // error handling
    let errorMessage = "Failed to ";
    switch (activeTab) {
      case NotificationTab.Inbox:
      case NotificationTab.ArchiveList:
        errorMessage += "move notification to trash";
        break;
      case NotificationTab.ConfigurationsAdmin:
        errorMessage += "delete configuration";
        break;
      case NotificationTab.GroupsAdmin:
        errorMessage += "delete group";
        break;
      default:
        break;
    }
    message.error(errorMessage);
    CommonError(error);
    setDeleteLoading(false);
  } finally {
    setDeleteLoading(false);
  }
};
